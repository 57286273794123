/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useMemo } from 'react';
import DynamicHeader from '../landingComponents/DynamicHeader';
// import DynamicFooter from "../landingComponents/DynamicFooter";
import { colors, typography } from '../../../styles/common';
const MumbaiFestLayout = (props) => {
    var _a, _b, _c;
    const pageData = (_a = props === null || props === void 0 ? void 0 : props.pageData) !== null && _a !== void 0 ? _a : {};
    const navUrl = (_b = pageData === null || pageData === void 0 ? void 0 : pageData.navUrl) !== null && _b !== void 0 ? _b : '';
    const navRoute = useMemo(() => [
        {
            title: 'Home',
            link: navUrl,
            sectionId: navUrl
        },
        {
            title: 'Schedule',
            link: `${navUrl}/schedule`,
            sectionId: `${navUrl}/schedule`
        }
    ], []);
    return (<div css={css([typography.chromeBody, layoutStyle])} style={{ background: colors.greyBackground }}>
          <DynamicHeader homeLink={navUrl} title={(_c = pageData === null || pageData === void 0 ? void 0 : pageData.headerTitle) !== null && _c !== void 0 ? _c : ''} logoImg="/assets/images/mumbaiFest/featLogo.png" isFromApp={props.isFromApp} isSpa={false} navRoute={navRoute} customStyle={{ bgColor: '#0C172F' }}/>
          {props.children}
          {/* <DynamicFooter title='Get your answers' freshDeskId={pageData?.faqId ?? ""} /> */}
      </div>);
};
export default MumbaiFestLayout;
const layoutStyle = `
    padding-bottom: 15px;
    margin-bottom: 30px;
    * {
        scroll-behavior: smooth;
    }
`;
