import React from "react";
import { connect } from "react-redux";
import { fetchUser, setSPAMode, setPlatform, setSocialLoginFlag } from "../actions/index";
import { getLoginDataReceived, getModals, getCitiesGroupedByPinned, getSelectedCityName, isFromApp } from "../selectors/index";
import InsiderApp from "../components/InsiderApp";
const mapStateToProps = state => ({
    isSPA: state.getIn(["uiState", "isSPA"]),
    notifications: state.getIn(["notifications", "alerts"]),
    modalType: getModals(state).get("modalType"),
    loginDataRecieved: getLoginDataReceived(state),
    messages: state.getIn(["notifications", "messages"]),
    cities: getCitiesGroupedByPinned(state),
    selectedCity: getSelectedCityName(state),
    isFromApp: isFromApp(state),
});
const mapDispatchToProps = dispatch => ({
    setSPAMode: isSPA => dispatch(setSPAMode(isSPA)),
    setPlatform: platform => dispatch(setPlatform(platform)),
    setSocialLoginFlag: () => dispatch(setSocialLoginFlag()),
    fetchUser: () => dispatch(fetchUser())
});
const InsiderAppWithSubscription = connect(mapStateToProps, mapDispatchToProps)(InsiderApp);
export default InsiderAppWithSubscription;
