const initialiseFBTracking = () => {
    // fetch("/users/isLoggedIn", {
    // 	headers: { "Accept-Encoding": "gzip" },
    // 	credentials: "same-origin"
    // })
    // 	.then(function(response) {
    // 		return response.json();
    // 	})
    // 	.then(function(data) {
    // 		if (window.fbq) {
    // 			if (data && data.email) {
    // 				window.fbq("init", "1014217412290897", { em: data.email });
    // 				window.fbq("track", "PageView");
    // 			} else {
    // 				window.fbq("init", "1014217412290897");
    // 				window.fbq("track", "PageView");
    // 			}
    // 		}
    // 	});
};
export { initialiseFBTracking };
