import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import { stripHrefDomain } from "../helpers/misc";
import queryString from "query-string";
import { get } from "lodash";
const getPathAndSearchParam = (strippedDomainHref) => {
    let pathnameParam = strippedDomainHref, searchParam;
    try {
        [pathnameParam, searchParam] = strippedDomainHref.split("?");
    }
    catch (e) {
        // fall through
    }
    return {
        pathnameParam,
        searchParam
    };
};
const getFilteredQueryParams = (searchParams) => {
    const ALLOWED_QUERY_PARAMS = ["platform", "version", "embed", "merchantId"];
    const queryParams = queryString.parse(searchParams);
    const query = {};
    Object.keys(queryParams).forEach(queryParam => {
        if (ALLOWED_QUERY_PARAMS.includes(queryParam)) {
            query[queryParam] = queryParams[queryParam];
        }
    });
    return queryString.stringify(query);
};
export const InsiderLink = props => {
    var _a;
    const { activeClassName, btnBgColor, children, className = "", href, isSPA, onClick, doAutoLinkActivation = true, dataRef, target = undefined, id = undefined } = props;
    const styleAttrs = btnBgColor ? { backgroundColor: btnBgColor } : undefined;
    const searchParams = get(window, "location.search", "");
    const filteredQueryParams = getFilteredQueryParams(searchParams);
    const [hrefWithPlatformParams, setHrefWithPlatformParams] = useState(href);
    useEffect(() => {
        let newHref = href;
        if (filteredQueryParams) {
            newHref = `${hrefWithPlatformParams}${newHref.includes("?") ? `&${filteredQueryParams}` : `?${filteredQueryParams}`}`;
        }
        setHrefWithPlatformParams(newHref);
    }, [filteredQueryParams]);
    if (isSPA) {
        const { pathnameParam, searchParam } = getPathAndSearchParam(stripHrefDomain(href));
        const linkSearchParams = searchParam
            ? `${searchParam}${filteredQueryParams ? `&${filteredQueryParams}` : ""}`
            : filteredQueryParams;
        return (<Link id={id} activeClassName={doAutoLinkActivation && activeClassName ? activeClassName : ""} className={className} onClick={onClick} style={styleAttrs} to={{
                pathname: pathnameParam,
                search: linkSearchParams && `?${linkSearchParams}`,
                state: { prevPath: (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.href }
            }} data-ref={dataRef} target={target}>
				{children}
			</Link>);
    }
    return (<a className={className} href={hrefWithPlatformParams} style={styleAttrs} onClick={onClick} data-ref={dataRef} target={target}>
			{children}
		</a>);
};
// Link adds the active class when the href and the location matches on following basis:
// If a link has a query, the query must match in order for it to be active. If it doesn't have a query, it only needs to match the route.
// Its explained here: https://github.com/ReactTraining/react-router/issues/2077
// This is an issue...To handle this case....do activeoverride with exactMatch and set autoLinkActivation to false
