import { createSelector } from "reselect";
import { getCart, getCartData } from "../selectors";
import { List } from "immutable";
import parseISO from "date-fns/parseISO";
import compareAsc from "date-fns/compareAsc";
export const getCartItemCount = createSelector(getCartData, (cart) => {
    return cart.get("items", List()).reduce((sum, item) => sum + parseInt(item.get("count"), 10), 0);
});
export const getCartItemsData = createSelector(getCartData, (cart) => {
    return cart.get("items", List());
});
export const getCartExpiry = createSelector(getCartData, (cart) => {
    return cart.get("expires");
});
export const getClockSkewForCart = createSelector(getCart, cart => {
    return cart.get("clockSkew");
});
export const getIsCartEmpty = createSelector(getCartData, (cart) => {
    return cart.size === 0;
});
export const getIsPaytmPromoCalled = state => {
    return state
        .getIn(["cartData", "paytmPromoCodeDataReceived"], false);
};
export const getPaytmPromoCodes = state => {
    const now = Date.now();
    return state
        .getIn(["cartData", "paytmPromoCodes"], List())
        .filter(code => compareAsc(now, parseISO(code.get("visible_from"))) === 1)
        .filter(code => compareAsc(now, parseISO(code.get("visible_upto"))) === -1);
};
export const getPaytmPromoCodeCount = state => {
    return getPaytmPromoCodes(state).size;
};
export const getAppliedPromoCode = state => state.getIn(["cartData", "cart", "coupon", "code"]);
export const getPromoCodeDisplayText = state => state.getIn(["cartData", "cart", "coupon", "display_text"]);
export const getAddItemError = state => state.getIn(["cartData", "addItemError"], "");
export const getItemWithError = state => state.getIn(["cartData", "errorItem"], {});
export const getAddItemErrorStatusCode = state => state.getIn(["cartData", "addItemErrorStatusCode"], "");
