import { connect } from "react-redux";
// components
import MumbaiFestLayout from "../components/DynamicEventsLanding/mumbaiFest/MumbaiFestLayout";
import { isFromApp } from "../selectors";
import { data } from "../components/DynamicEventsLanding/mumbaiFest/mumbaiFestData";
const mapStateToProps = (state) => ({
    isFromApp: isFromApp(state),
    pageData: data,
});
const mapDispatchToProps = (dispatch) => ({});
const MumbaiEventLandingLayoutWithSubscription = connect(mapStateToProps, mapDispatchToProps)(MumbaiFestLayout);
// set static on this component
// for its preload chunk
export default MumbaiEventLandingLayoutWithSubscription;
