import { connect } from "react-redux";
// components
import IPLLayout from "../components/IPL/Layout/IPLLayout";
import { isFromApp } from "../selectors";
const mapStateToProps = (state) => ({
    isFromApp: isFromApp(state),
});
const mapDispatchToProps = (dispatch) => ({});
const IPLLayoutWithSubscription = connect(mapStateToProps, mapDispatchToProps)(IPLLayout);
// set static on this component
// for its preload chunk
export default IPLLayoutWithSubscription;
