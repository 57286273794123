import React from "react";
import { errorLoading } from "../helpers/misc";
const asyncDynamicListHoc = (entity = "tag") => (_, cb) => import(/* webpackChunkName: "DynamicListPage" */ "../containers/DynamicListWithSubscription")
    .then(dynamicListBundle => dynamicListBundle.default)
    .then(DynamicListContainer => {
    const DynamicListWithSubscriptionWrapper = props => <DynamicListContainer entity={entity} {...props}/>;
    DynamicListWithSubscriptionWrapper.preloadChunkName = DynamicListContainer.preloadChunkName;
    DynamicListWithSubscriptionWrapper.cacheString = DynamicListContainer.cacheString;
    // hoist fetch data one level higher
    DynamicListContainer.entity = entity; // explicitly set the entity
    DynamicListWithSubscriptionWrapper.fetchData = DynamicListContainer.fetchData;
    return cb(null, DynamicListWithSubscriptionWrapper);
})
    .catch(errorLoading);
export default asyncDynamicListHoc;
