import React from "react";
import { errorLoading } from "../helpers/misc";
const asyncCategoryHoc = (category, timeFilter) => (_, cb) => import(/* webpackChunkName: "CategoryPage" */ "../containers/CategoryViewWithSubscription")
    .then(categoryBundle => categoryBundle.default)
    .then(CategoryContainer => {
    const CategoryViewWithSubscriptionWrapper = props => (<CategoryContainer category={category} timeFilter={timeFilter} {...props}/>);
    CategoryViewWithSubscriptionWrapper.preloadChunkName = "CategoryPage";
    CategoryViewWithSubscriptionWrapper.cacheString = "public, max-age=300";
    // hoist fetch data one level higher
    CategoryViewWithSubscriptionWrapper.fetchData = CategoryContainer.fetchData;
    return cb(null, CategoryViewWithSubscriptionWrapper);
})
    .catch(errorLoading);
export default asyncCategoryHoc;
