export const getLocation = () => Promise.resolve(null);
export const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        try {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((response) => {
                    const lat = response.coords.latitude;
                    const long = response.coords.longitude;
                    resolve({
                        lat,
                        long
                    });
                }, (error) => {
                    reject(error);
                });
            }
        }
        catch (e) {
            reject(e);
        }
    });
};
export * from "./common";
