import { getApiKey, getApiSecret } from "../helpers/auth";
import uuid from "uuid/v4";
import hmacSignature from "./hmacSignature";
import { API_URL, devicePlatform } from "./constants";
import { fromJS } from "immutable";
import { handleSiteDataResponse, handleSiteDataErrors } from "./apiClient";
export const postToSiteWithAuth = function postToSiteWithAuth(resource, body) {
    console.log("body", body);
    const apiKey = getApiKey();
    const secret = getApiSecret();
    console.log(apiKey);
    console.log(secret);
    const date = new Date();
    const API_OPTIONS = {
        method: "POST",
        body,
        headers: {
            "API-KEY": apiKey,
            "Accept-Encoding": "gzip",
            "Content-Type": "application/json; charset=UTF-8",
            "X-Date": hmacSignature.formatDate(date),
            Authorization: hmacSignature.sign(date, apiKey, secret)
        },
        credentials: "same-origin"
    };
    console.log(`*************${API_URL}${resource}`);
    return fetch(`${API_URL}${resource}`, API_OPTIONS);
};
export const fetchSiteDataWithAuthWithoutHmac = function fetchSiteDataWithAuth(resource, parseToArrayBuffer = false, apiUrl = API_URL) {
    const apiKey = getApiKey();
    const secret = getApiSecret();
    const date = new Date();
    const requestId = uuid();
    // Request id is removed since theres no cors support for {it
    const API_OPTIONS = {
        headers: {
            "API-KEY": apiKey,
            "Accept-Encoding": "gzip",
            // "X-Date": hmacSignature.formatDate(date),
            "X-Platform": devicePlatform
            // Authorization: hmacSignature.sign(date, apiKey, secret)
        },
        credentials: "same-origin"
    };
    return fetch(`${apiUrl}${resource}`, API_OPTIONS)
        .then(handleSiteDataResponse)
        .then(response => {
        if (parseToArrayBuffer) {
            return response.arrayBuffer();
        }
        else {
            return response.json();
        }
    })
        .then(fromJS)
        .catch(handleSiteDataErrors);
};
export const fetchSiteDataWithAuth = function fetchSiteDataWithAuth(resource, parseToArrayBuffer = false, headers = {}, apiUrl = API_URL) {
    const apiKey = getApiKey();
    const secret = getApiSecret();
    const date = new Date();
    const requestId = uuid();
    // Request id is removed since theres no cors support for {it
    const API_OPTIONS = {
        headers: Object.assign({ "API-KEY": apiKey, "Accept-Encoding": "gzip", "X-Date": hmacSignature.formatDate(date), "X-Platform": devicePlatform, Authorization: hmacSignature.sign(date, apiKey, secret) }, headers),
        credentials: "same-origin"
    };
    return fetch(`${apiUrl}${resource}`, API_OPTIONS)
        .then(handleSiteDataResponse)
        .then(response => {
        if (parseToArrayBuffer) {
            return response.arrayBuffer();
        }
        else {
            return response.json();
        }
    })
        .then(fromJS)
        .catch(handleSiteDataErrors);
};
export const postJSONSiteDataWithAuth = function postJSONSiteDataWithAuth(resource, body, parseToArrayBuffer = false, apiUrl = API_URL) {
    const apiKey = getApiKey();
    const secret = getApiSecret();
    const date = new Date();
    const API_OPTIONS = {
        method: "POST",
        headers: {
            "API-KEY": apiKey,
            "Accept-Encoding": "gzip",
            "X-Date": hmacSignature.formatDate(date),
            "X-Platform": devicePlatform,
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: hmacSignature.sign(date, apiKey, secret)
        },
        body: body,
        credentials: "same-origin"
    };
    return fetch(`${apiUrl}${resource}`, API_OPTIONS)
        .then(handleSiteDataResponse)
        .then(response => {
        if (parseToArrayBuffer) {
            return response.arrayBuffer();
        }
        else {
            return response.json();
        }
    })
        .then(fromJS)
        .catch(handleSiteDataErrors);
};
