import React from "react";
import { errorLoading } from "../helpers/misc";
const asyncEverythingPage = (timeFilter) => (_, cb) => import(/* webpackChunkName: "EverythingPage" */ "../containers/EverythingViewWithSubscription")
    .then(everythingBundle => everythingBundle.default)
    .then(EverythingContainer => {
    const EverythingViewWithSubscriptionWrapper = props => <EverythingContainer timeFilter={timeFilter} {...props}/>;
    EverythingViewWithSubscriptionWrapper.preloadChunkName = "EverythingPage";
    EverythingViewWithSubscriptionWrapper.cacheString = "public, max-age=300";
    // hoist fetch data one level higher
    EverythingViewWithSubscriptionWrapper.fetchData = EverythingContainer.fetchData;
    return cb(null, EverythingViewWithSubscriptionWrapper);
})
    .catch(errorLoading);
export default asyncEverythingPage;
