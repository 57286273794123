/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import IPLHeader from "../../IPL/Components/IPLHeader";
import { colors, typography } from "../../../styles/common";
import IPLFooter from "../Components/IPLFooter";
const IPLLayout = props => {
    var _a, _b;
    return (<div css={css([typography.chromeBody, layoutStyle])} style={{ background: colors.greyBackground }}>
			<IPLHeader isFromApp={(_a = props === null || props === void 0 ? void 0 : props.isFromApp) !== null && _a !== void 0 ? _a : false}/>
			{props.children}
			<IPLFooter isFromApp={(_b = props === null || props === void 0 ? void 0 : props.isFromApp) !== null && _b !== void 0 ? _b : false}/>
		</div>);
};
export default IPLLayout;
const layoutStyle = `
    padding-bottom: 15px;
    margin-bottom: 30px;
    * {
        scroll-behavior: smooth;
    }
`;
