import { connect } from "react-redux";
import HomePageCitySelector from "../components/HomePageCitySelector/HomePageCitySelector";
import { getSelectedCityName } from "../selectors";
import { toggleBodyScroll } from "../actions";
const mapStateToProps = (state) => ({
    selectedCity: getSelectedCityName(state)
});
const mapDispatchToProps = (dispatch) => ({
    toggleBodyScroll: (lockBodyScroll) => dispatch(toggleBodyScroll(lockBodyScroll))
});
export default connect(mapStateToProps, mapDispatchToProps)(HomePageCitySelector);
