export function sendClevertapEvent(event, payload) {
    try {
        if (window.clevertap && clevertap.event) {
            clevertap.event.push(event, payload);
        }
    }
    catch (e) {
        // Fall through
    }
}
export function setClevertapIdentify({ first_name, last_name, email, _id, phone_no }) {
    try {
        if (window.clevertap && clevertap.onUserLogin) {
            clevertap.onUserLogin.push({
                Site: {
                    Name: `${first_name} ${last_name}`,
                    Email: email,
                    Identity: _id,
                    Phone: phone_no
                }
            });
        }
    }
    catch (e) {
        // Fall through
    }
}
export function sendClevertapOnUserLoginEvent(userData, payload) {
    try {
        if (window.clevertap && clevertap.onUserLogin && clevertap.event) {
            clevertap.onUserLogin.push({
                Site: {
                    Name: `${userData.get("first_name")} ${userData.get("last_name")}`,
                    Email: userData.get("email"),
                    Identity: userData.get("_id"),
                    Phone: userData.get("phone_no")
                }
            });
            clevertap.event.push("Login/Signup Successful", Object.assign(payload, {
                url: window.location.pathname
            }));
        }
    }
    catch (e) {
        // Fall through
    }
}
export function updateClevertapUserProfile(payload) {
    try {
        if (window.clevertap && clevertap.profile) {
            clevertap.profile.push({
                Site: payload
            });
        }
    }
    catch (e) {
        // Fall through
    }
}
