import React from "react";
const shouldShowInlineSearch = (props, isMobile) => {
    const { location, searchBarInView, selectedCity, showOnDesktop } = props;
    const inlineSearchUrls = [`/${selectedCity}`, "/"];
    if (location && inlineSearchUrls.includes(location.pathname)) {
        if (searchBarInView && (isMobile || showOnDesktop)) {
            return true;
        }
    }
    return false;
};
const withInlineSearchBoolean = (ownProps) => (WrappedComponent) => {
    class WrappedComponentWithInlineSearchBoolean extends React.Component {
        constructor() {
            super(...arguments);
            this.state = {
                isMobile: false
            };
        }
        componentDidMount() {
            this.setState({
                isMobile: !window.matchMedia("(min-width: 64em)").matches
            });
        }
        render() {
            const showInlineSearch = shouldShowInlineSearch(this.props, this.state.isMobile);
            return (<WrappedComponent {...this.props} {...ownProps} isMobile={this.state.isMobile} showInlineSearch={showInlineSearch}/>);
        }
    }
    return WrappedComponentWithInlineSearchBoolean;
};
export default withInlineSearchBoolean;
