import React from "react";
import ErrorComponent from "../ErrorComponent";
class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { hasError: false };
    }
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }
    render() {
        if (this.state.hasError) {
            return <ErrorComponent />;
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
