import { createSelector } from "reselect";
import { fromJS, List, Map, OrderedMap } from "immutable";
import { capitalizeFirstLetter, DEFAULT_CATEGORY_LOGO, ELPSorters, replaceCityAndTimePlaceholder, VENUES_CAROUSEL_MAX_CARDS, HomePageGroupCarouselConstants, GLOBAL_TAG, updatedCityName } from "../helpers/constants";
import { filterAndSortCards } from "../filters";
import { unique, slugify } from "../helpers/misc";
import { getCurrentHomepageData, getDisplayMode, getHomepageData, getMasterList, getUiState } from "./homepage";
import { getCurrentElpData, getElpMasterList, getELPBanners } from "./elp";
import { colors } from "../styles/common";
////// PROPS SELECTORS //////
/**
 * These selectors extract various different
 * keys out from props
 */
const getCategoryFromProps = (_, props) => props.category;
const getCategoryFromQueryParamProps = (_, props) => props.location.query.category;
const getTypeFilterFromQueryParamProps = (_, props) => props.location.query.type;
const getTimeFilterFromQueryParamProps = (_, props) => props.location.query.timeFilter;
const getGroupFromProps = (_, props) => props.group;
const getSorterFromProps = (_, props) => props.selectedSorter || ELPSorters.get(0);
////// CITIES SELECTORS //////
/**
 * List of cities
 * @param {Immutable.Map} state
 * @return {Immutable.List}
 */
export const getAllCities = state => state.getIn(["citiesData", "cities"]);
/**
 * Get all city names, lower cased
 * @return {Immutable.List}
 */
export const getAllCityNames = createSelector([getAllCities], cities => cities.map(c => c.get("name", "").toLowerCase()));
/**
 * Fetched the current selected city
 * @return {String}
 */
export const getSelectedCityName = state => state.getIn(["citiesData", "selectedCity"]);
/**
 * Gets the flag that tells us whether the city is default
 * or have we inferred it
 * @return {String}
 */
export const getIsSelectedCityInferred = state => state.getIn(["citiesData", "isSelectedCityInferred"]);
/**
 * Fetched the current selected city
 * @return {Immutable.Map}
 */
export const getSelectedCityCoordinates = createSelector([getAllCities, getSelectedCityName], (cities, selectedCity) => {
    const selectedCityName = updatedCityName(selectedCity);
    const cityFound = cities.find(c => c.get("name").toLowerCase() === selectedCityName);
    return cityFound && Map.isMap(cityFound) ? cityFound.get("geolocation", new Map({})) : new Map({});
});
/**
 * Fetched the current selected city
 * @return {Immutable.Map}
 */
export const getSelectedCity = createSelector([getAllCities, getSelectedCityName], (cities, selectedCity) => cities.find(c => c.get("name").toLowerCase() === selectedCity));
/**
 * Gets just the name of the selected city
 * @return {String}
 */
export const getSelectedCityId = createSelector([getSelectedCity], possibleSelectedCity => {
    if (possibleSelectedCity) {
        return possibleSelectedCity.get("_id");
    }
    return ""; // default city
});
/**
 * Gets the capitalized name of the city
 * @return {String}
 */
export const getCapitalizedSelectedCityName = createSelector([getSelectedCityName], cityName => capitalizeFirstLetter(cityName));
/**
 * Splits cities into "pinned" and "unpinned"
 * Pinned cities are always shown at the top
 * @return {Immutable.OrderedMap}
 */
export const getCitiesGroupedByPinned = createSelector([getAllCities], cities => {
    // OrderedMap can be built from an array of key-value pairs
    // We use a default here for the case where the only active cities
    // are the pinned ones, or vice versa.
    const defaultCityGrouping = new OrderedMap([
        [true, new List()],
        [false, new List()]
    ]);
    return defaultCityGrouping.merge(cities.groupBy(c => {
        if (c.get("name").toLowerCase() !== "online") {
            return c.get("pinned");
        }
        return null;
    }));
});
export const getUnpinnedCities = createSelector([getAllCities], cities => {
    return cities.filter(c => c.get("pinned") === false).map(c => c.get("name").toLowerCase());
});
////// CITIES SELECTORS //////
////// UI STATE SELECTORS //////
/**
 * Get if App is running in SPA
 * @param {Immutable.Map} state
 * @return {boolean}
 */
export const getIsSPA = createSelector([getUiState], uiState => uiState.get("isSPA"));
/**
 * @return {boolean}
 */
export const getShouldShowCitySelector = createSelector([getUiState], uiState => uiState.get("shouldShowCitySelector"));
/**
 * Show the nav search bar icon or not based on search on homepage
 * @return {boolean}
 */
export const getSearchBarInView = createSelector([getUiState], uiState => uiState.get("searchBarInView"));
////// UI STATE SELECTORS //////
////// GROUPS SELECTORS //////
/**
 * Get Groups from reducer
 * @param {Immutable.Map} state
 * @return {Immutable.List}
 */
export const getGroups = state => state.get("groups");
/**
 * Get Groups for Display mode; go-out/stay-in
 */
export const getGroupsForDisplayMode = createSelector([getDisplayMode, getGroups], (displayMode, groups) => groups.get(displayMode));
////// GROUPS SELECTORS //////
/**
 * Gets all the tab (list) data
 * @return {Immutable.Map}
 */
export const getListData = createSelector([getCurrentHomepageData], homepageData => homepageData.get("list"));
/**
 * Get Groups from master Route Groups
 * @param state
 */
export const getGroupsForRoutes = state => state.getIn(["routes", "groups"]);
/**
 * Get Categories from master Route Categories
 * @param state
 */
export const getCategoriesForRoutes = state => state.getIn(["routes", "categories"]);
export const getTimeFilter = (_, props) => {
    return props.timeFilter || (props.location && props.location.query && props.location.query.timeFilter);
};
export const getPriceFilter = (_, props) => props.priceFilter;
export const getTypeFilter = (_, props) => {
    return props.typeFilter ? props.typeFilter : props.typeValue;
};
export const getPriceRangeFilter = (_, props) => {
    return props.location && props.location.query && props.location.query.maxPrice;
};
export const getGoOutGroups = state => state.getIn(["groups", "go-out"]);
export const getGroupCards = (state, groupName) => state.getIn(["homepageData", "go-out", "list", "groupwiseList", groupName]);
export const getCategoryCards = (state, categoryName) => state.getIn(["homepageData", "go-out", "list", "categorywiseList", categoryName]);
// Helper to get card count from card data
function getCountFromCardData(cards) {
    const totalCount = cards.size;
    let physicalCount = 0;
    let onlineCount = 0;
    cards.filter(card => {
        if (card.get("event_type") == "online") {
            onlineCount++;
        }
        else if (card.get("event_type") == "physical") {
            physicalCount++;
        }
        return true;
    });
    return {
        all: totalCount,
        physical: physicalCount,
        online: onlineCount
    };
}
/**
 * Get Categories from reducer
 * @param state
 */
export const getCategories = createSelector([getListData], listData => listData.get("categorywiseList"));
export const getGroupsFromList = createSelector([getListData], listData => listData.get("groupwiseList"));
export const getGroupDetails = createSelector([getGroupsForRoutes, getGoOutGroups], (groupsForRoutes, goOutGroups) => {
    return goOutGroups.map(name => {
        return groupsForRoutes.find(group => {
            return group.get("name") === name;
        });
    });
});
const sortCategoriesByEventCount = (a, b) => {
    const firstCount = a.get("eventCount");
    const secondCount = b.get("eventCount");
    if (firstCount !== secondCount) {
        return secondCount - firstCount;
    }
    const firstName = a.get("name");
    const secondName = b.get("name");
    if (firstName > secondName) {
        return 1;
    }
    if (firstName < secondName) {
        return -1;
    }
    return 0;
};
export const getHomeCategories = createSelector([getCurrentHomepageData], homepageData => homepageData.get("categories"));
/**
 * Get Grouped Category counts for HomePage
 * @return {Immutable.List}
 */
export const getHomeCategoriesSortedByEventCount = createSelector([getHomeCategories], categories => {
    if (!categories) {
        return new List();
    }
    return categories.map(category => {
        const categoryName = category.get("name");
        const categoryCount = category.get("eventCount", 0);
        const catObj = new Map({
            model: "category",
            name: capitalizeFirstLetter(categoryName),
            eventCount: categoryCount,
            src: category.get("src", DEFAULT_CATEGORY_LOGO)
        });
        return catObj;
    });
});
/**
 * Gets all the popular data for the current
 * display mode
 * @return {Immutable.List}
 */
export const getPopularData = createSelector([getCurrentHomepageData], homepageData => homepageData.get("popular"));
export const getCategoriesSortedByEventCountNames = createSelector([getHomeCategoriesSortedByEventCount], CategoriesSortedByEventCount => {
    return CategoriesSortedByEventCount.map(category => new Map({
        name: category.get("name"),
        src: category.get("src")
    }));
});
export const getCategoryEvents = createSelector([getMasterList, getCategoriesSortedByEventCountNames], (masterList, categories) => {
    if (masterList.size === 0 || categories.size === 0) {
        return new List();
    }
    const categoryEvents = categories.map(category => {
        const events = masterList.filter(event => {
            return capitalizeFirstLetter(event.getIn(["category_id", "name"])) === category.get("name");
        });
        const categoryObj = new Map({
            name: category.get("name"),
            src: category.get("src"),
            events: filterAndSortCards(events.toList(), null, ELPSorters.get(0), "", null)
        });
        return categoryObj;
    });
    return categoryEvents;
});
// gets masterlist from /home api and creates a categorywise list that is sorted by event count
export const getHomePageEventsByGenre = createSelector([getElpMasterList, getHomeCategories], (masterList, categories) => {
    if (!masterList || masterList.size === 0 || !categories || categories.size === 0) {
        return new List();
    }
    const categoryEvents = categories.map(category => {
        const events = masterList.filter(event => {
            return capitalizeFirstLetter(event.getIn(["category_id", "name"])) === category.get("name");
        });
        const cards = filterAndSortCards(events.toList(), null, ELPSorters.get(0), "", null);
        const categoryObj = new Map({
            name: category.get("name"),
            src: category.get("src"),
            events: cards,
            eventCount: cards.size
        });
        return categoryObj;
    });
    return categoryEvents
        .filter(card => card.get("eventCount"))
        .sort((a, b) => b.get("eventCount") - a.get("eventCount"));
});
export const getTypewiseList = createSelector([getCurrentHomepageData], homepageData => {
    return homepageData.getIn(["list", "typewiseList"]);
});
export const getPhysicalEvents = createSelector([getMasterList, getTypewiseList], (masterList, typewiseList) => {
    let physicalEvents = [];
    if (typewiseList.size > 0) {
        typewiseList.get("physical").forEach(slug => {
            physicalEvents.push(masterList.get(slug));
        });
    }
    const physicalObj = new Map({
        name: "physical",
        events: physicalEvents
    });
    return physicalObj;
});
/**
 * Gets all the featured data for the current selected
 * display mode. Here we apply a sorter as well
 * There are some events, for which the
 * homeescreen-<city> tag has a carousel_position
 * set. If set, we need to honor it. But for the non
 * carousel ones, it isn't set at all. Therefore, we
 * need to ensure that we have a sane default sorting
 * strategy for the non carousel featured data
 * We do this by sorting, ascending, using
 * carousel_position || min_show_start_time. This means
 * that if there is a position set, for eg - 0, 2, 3 etc,
 * it'll come in that position, and if there isn't one set,
 * we should ideally show the earliest featured events first
 * @return {Immutable.List}
 */
export const getFeaturedData = createSelector([getCurrentHomepageData, getSelectedCityId], (homepageData, cityTagId) => {
    // due to some babel issue, spread operator doesn't work on string in client side.
    // this is causing a mismatch in behaviour on ssr and csr
    // The isArray check code is required as we dont want to spread if city tag is a string.
    // TODO: figure this behaviour mismatch of spread operator on CSR and SSR.
    const allTags = Array.isArray(cityTagId) ? [...cityTagId, GLOBAL_TAG] : [cityTagId, GLOBAL_TAG];
    return homepageData
        .get("featured")
        .filter(model => model.get("model") !== "article")
        .sortBy((featuredCard, idx) => featuredCard
        .get("tags")
        .filter(tag => allTags.includes(tag.get("tag_id")))
        .getIn([0, "carousel_position"], featuredCard.get("min_show_start_time")));
});
export const getFeaturedStayInData = createSelector([getCurrentHomepageData, getSelectedCityId], (homepageData, cityTagId) => homepageData.get("featured").sortBy((featuredCard, idx) => featuredCard
    .get("tags")
    .filter(tag => tag.get("tag_id") === cityTagId)
    .getIn([0, "carousel_position"], featuredCard.get("min_show_start_time"))));
export const getSearchData = state => state.get("searchData");
export const getTrendingSearchResults = state => state.getIn(["homepageData", "go-out", "popular"], new List());
export const isUserSearching = createSelector([getSearchData], searchData => searchData.get("isSearching"));
export const isAlgoliaSearchEmpty = createSelector([getSearchData], searchData => searchData.get("resultsState").every(index => index.get("hits").size === 0));
export const getAlgoliaSearchData = createSelector([getSearchData], searchData => searchData.get("resultsState").toJS());
export const getUserSearchData = createSelector([getSearchData], searchData => searchData.get("user"));
export const getIndexSearchData = (state, ownProps) => {
    return ownProps.cards;
};
export const getSearchResultForUserSelectedCity = createSelector([getSelectedCityId, getIndexSearchData], (userSelectedCityId, userSearchData) => {
    return userSearchData.filter(searchResultRow => {
        if (searchResultRow.has("tagids")) {
            const tagid = searchResultRow.get("tagids").find(tid => tid === userSelectedCityId);
            if (tagid !== undefined) {
                return true;
            }
        }
        else {
            return false;
        }
    });
});
export const getSearchResultForUserOtherCity = createSelector([getSelectedCityId, getIndexSearchData], (userSelectedCityId, userSearchData) => {
    return userSearchData.filter(searchResultRow => {
        if (searchResultRow.has("tagids")) {
            const tagid = searchResultRow.get("tagids").find(tid => tid === userSelectedCityId);
            if (tagid !== undefined) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    });
});
/**
 * Gets all the picks data
 * @return {Immutable.Seq}
 */
export const getPicksData = createSelector([getCurrentHomepageData], 
// we converto to value Seq here since
// carousel expects a list like`
homepageData => homepageData
    .getIn(["picks", "masterList"])
    .valueSeq()
    .sortBy(x => x.get("orderIndex")));
/**
 * Gets the list of all the tabs
 * @return {Immutable.Seq}
 */
export const getTabList = createSelector([getCurrentHomepageData], homepageData => homepageData.get("tabList"));
/**
 * Get all Groups
 * @param state
 */
export const getAllGroups = state => state.get("groups");
/**
 * Get Groups for displayMode
 * @return {Array}
 */
export const getAppBarTabList = createSelector([getDisplayMode, getAllGroups], (displayMode, groups) => {
    if (displayMode === "homepage") {
        return groups.getIn(["go-out"]);
    }
    else {
        return groups.getIn([displayMode]);
    }
});
// begin modals related selectors
/**
 * Get Modals state
 * @param state
 */
export const getModals = state => state.get("modals");
/**
 * Get Modal Context
 * @param state
 * @param ownProps
 */
export const getModalContext = (_, ownProps) => ownProps.context;
/**
 * get Modal type
 * @param state
 * @return {string}
 */
export const getModalType = createSelector([getModals], modal => modal.get("modalType"));
/**
 * get Modal type
 * @param state
 * @return {string}
 */
export const getModalKey = createSelector([getModals], modal => modal.get("modalKey"));
/**
 * should show login modal
 * @param state
 * @return {boolean}
 */
export const showLoginModal = createSelector([getModalType], modalType => ["login", "favourite", "reminder", "activity"].includes(modalType));
/**
 * get header modal type
 * @param state
 * @return {string}
 */
export const getHeaderModal = createSelector([getModals], modal => modal.get("header"));
// begin login modal related selectors
/**
 * Get Login Modal state
 * @param state
 */
export const getLoginModalState = state => state.get("loginModal");
/**
 * login modal screen
 * @param state
 * @return {string}
 */
export const loginModalScreen = createSelector([getLoginModalState], modal => modal.get("screen"));
/**
 * login modal redirectUrl
 * @param state
 * @return {string}
 */
export const loginModalRedirectUrl = createSelector([getLoginModalState], modal => modal.get("redirectUrl"));
/**
 * login modal error
 * @param state
 * @return {string}
 */
export const loginModalError = createSelector([getLoginModalState], modal => modal.get("loginError"));
/**
 * login modal verified email
 * @param state
 * @return {string}
 */
export const loginModalEmail = createSelector([getLoginModalState], modal => modal.get("email"));
// end login modal related selectors
//reminder modal related selectors
/**
 * event details for reminder
 * @param state
 * @return {string}
 */
export const getDetailsForReminder = createSelector([getModals], modal => modal.get("eventDetails"));
// begin user profile related selectors
/**
 * Get UserData
 * @param state
 */
export const getUserData = state => state.get("userData");
export const getLoginDataReceived = createSelector([getUserData], user => user.get("dataReceived"));
export const getLoginDataErrored = createSelector([getUserData], user => user.get("dataErrored"));
/**
 * Get Partner type
 * @param state
 * @param ownProps
 * @return {string}
 */
export const getPartnerType = (_, ownProps) => ownProps.type;
/**
 * Get PartnerId
 * @param state
 * @param ownProps
 * @return {string}
 */
export const getPartnerId = (_, ownProps) => ownProps._id;
/**
 * Get EventId
 * @param state
 * @param ownProps
 * @return {string}
 */
export const getEventId = (_, ownProps) => ownProps._id;
/**
 * Get EventId
 * @param state
 * @param ownProps
 * @return {string}
 */
export const getEventSlug = (_, ownProps) => ownProps.slug;
/**
 * get user profile
 * @param state
 * @return {Immutable.Map}
 */
export const getUserProfile = createSelector([getUserData], user => user.get("profile"));
/**
 * is user logged in
 * @param state
 * @return {boolean}
 */
export const isUserLoggedIn = createSelector([getUserData], user => user.get("isLoggedIn"));
/**
 * is user a beta user(used for live events)
 * @param state
 * @return {boolean}
 */
export const isBetaUser = createSelector([getUserData], user => user.get("isBetaUser"));
/**
 * is a users profile is complete
 * @param state
 * @return {boolean}
 */
export const isUserProfileComplete = createSelector([getUserData], user => user.get("isProfileComplete"));
/**
 * does user follow this artist
 * @param state
 * @param ownprops
 * @return {boolean}
 */
export const isUserFollowing = createSelector([getUserData, getPartnerId, getPartnerType], (user, _id, type) => {
    const partners = user.getIn(["following", type]) || [];
    return partners.findIndex(id => id === _id) > -1;
});
/**
 * User First Name
 * @param state
 * @return {string}
 */
export const getUserFirstName = createSelector([getUserData], user => user.getIn(["profile", "first_name"]));
export const getUserFullName = createSelector([getUserData], user => {
    if (user.getIn(["profile", "first_name"])) {
        if (user.getIn(["profile", "last_name"])) {
            return `${user.getIn(["profile", "first_name"])} ${user.getIn(["profile", "last_name"])}`;
        }
        return `${user.getIn(["profile", "first_name"])}`;
    }
});
/**
 * User Display Name
 * @param state
 * @return {string}
 */
export const getUserDisplayName = createSelector([getUserData], user => {
    if (user.getIn(["profile", "first_name"])) {
        if (user.getIn(["profile", "last_name"])) {
            return `${user.getIn(["profile", "first_name"])} ${user.getIn(["profile", "last_name"])}`;
        }
        return `${user.getIn(["profile", "first_name"])}`;
    }
    return `${user.getIn(["profile", "email"])}`;
});
/**
 * User Profile form fields
 * @param state
 * @return {string}
 */
export const getUserProfileFields = createSelector([getUserData], user => user
    .getIn(["profile", "additional_info"], new List([]))
    .filter(profileField => profileField.get("name") !== "location"));
/**
 * User Email
 * @param state
 * @return {string}
 */
export const getUserEmail = createSelector([getUserData], user => user.getIn(["profile", "email"]));
/**
 * User Id
 * @param state
 * @return {string}
 */
export const getUserId = createSelector([getUserData], user => user.getIn(["profile", "_id"]));
// end user profile related selectors
/**
 * Get Slug
 * @param state
 * @param props
 */
export const getSlug = (_, props) => props.params.slug;
/**
 * Get cities in props separated by '&'
 * and return array of homescreen tags along with homescreen-online tag
 * @param state
 * @param props
 * @return {string[]} homescreen tags
 */
export const homeScreenSlugs = (_, props) => {
    const cities = props.params.city && props.params.city.split("&");
    if (!cities || cities.length === 0)
        return [];
    return ["homescreen-online", ...cities.map(tag => `homescreen-${tag.toLowerCase()}`)];
};
/**
 * Get Slug from ownprops, when component is not routing component
 * @param state
 * @param props
 */
export const getOwnSlug = (_, props) => props.slug;
// begin artist related selectors
/**
 * Get Artists
 * @param state
 */
export const getArtists = state => state.get("artists");
/**
 * Get All Artists
 * @param state
 * @return {Immutable.List}
 */
export const getAllArtists = createSelector([getArtists], artists => artists.get("all"));
/**
 * Get Artists in City
 * @param state
 * @return {Immutable.List}
 */
export const getCityArtists = createSelector([getArtists], artists => artists.get("list"));
/**
 * Get Artist
 * @param slug
 * @param state
 * @return {Immutable.Map}
 */
export const getArtist = createSelector([getArtists, getSlug], (artists, slug) => artists.getIn(["artist", slug]));
/**
 * Get Artist updates
 * @param state
 * @return {Immutable.Map}
 */
export const getArtistUpdates = createSelector([getArtists, getSlug], (artists, slug) => artists.getIn(["updates", slug]));
/**
 * Get Artist Details
 * @param slug
 * @param state
 * @return {Immutable.Map}
 */
export const getArtistDetails = createSelector([getArtists, getOwnSlug], (artists, slug) => {
    const hero = artists.getIn(["artist", slug, "hero"]);
    const name = artists.getIn(["artist", slug, "name"]);
    let image;
    let modalText;
    if (hero) {
        if (hero.get("banner_background_type") === "image") {
            image = hero.get("banner_image");
        }
        else {
            image = hero.getIn(["carousel", "0"]);
        }
        modalText = hero.get("modal_text");
    }
    return {
        image,
        modalText,
        name
    };
});
// begin venues related selectors
/**
 * Get Venues
 * @param state
 */
export const getVenues = state => state.get("venues");
/**
 * Get All Venues
 * @param state
 * @return {Immutable.List}
 */
export const getAllVenues = createSelector([getVenues], venues => venues.get("all"));
/**
 * Get Venues in City
 * @param state
 * @return {Immutable.List}
 */
export const getCityVenues = createSelector([getVenues], venues => venues.get("list") && venues.get("list"));
/**
 * Get Venues in City for venue carousel
 * @param state
 * @return {Immutable.List}
 */
export const getCarouselCityVenues = createSelector([getVenues], venues => venues.get("list") ? venues.get("list").take(VENUES_CAROUSEL_MAX_CARDS) : new List([]));
/**
 * Get Venue
 * @param slug
 * @return {Immutable.Map}
 */
export const getVenue = createSelector([getVenues, getSlug], (venues, slug) => venues.getIn(["venue", slug]));
/**
 * Get Venue updates
 * @param state
 * @return {Immutable.Map}
 */
export const getVenueUpdates = createSelector([getVenues, getSlug], (venues, slug) => venues.getIn(["updates", slug]));
/**
 * Get Venus Details
 * @param state
 * @param slug
 * @return {Immutable.Map}
 */
export const getVenueDetails = createSelector([getVenues, getOwnSlug], (venues, slug) => {
    const hero = venues.getIn(["venue", slug, "hero"]);
    const name = venues.getIn(["venue", slug, "name"]);
    let image;
    let modalText;
    if (hero) {
        if (hero.get("banner_background_type") === "image") {
            image = hero.get("banner_image");
        }
        else {
            image = hero.getIn(["carousel", "0"]);
        }
        modalText = hero.get("modal_text");
    }
    return {
        image,
        modalText,
        name
    };
});
/**
 * Get Explore Menu toggled state
 * @param state
 * @return {Boolean}
 */
export const getExploreMenuShowing = state => state.getIn(["uiState", "isExploreMenuShowing"]);
/**
 * Get Mobile Menu toggled state
 * @param state
 * @return {Boolean}
 */
export const getMobileMenuShowing = state => state.getIn(["uiState", "isMobileMenuShowing"]);
export const shouldShowMobileMenu = createSelector([getShouldShowCitySelector, getMobileMenuShowing], (shouldShowCitySelector, isMobileMenuShowing) => {
    return shouldShowCitySelector || isMobileMenuShowing;
});
/**
 * Get first 5 Article Cards for Stay In Panel
 * @param (state)
 * @return {List}
 */
export const getStayInFeaturedArticles = state => state.getIn(["homepageData", "stay-in", "featured"]);
export const getStayInPanelCards = createSelector([getStayInFeaturedArticles], featuredArticles => {
    return featuredArticles.take(5);
});
export const getCategoryNameFromResource = cardData => {
    const nestedCategoryName = cardData.getIn(["category_id", "name"]);
    const topLevelCategoryName = cardData.get("category");
    const defaultCategoryName = "";
    return nestedCategoryName || topLevelCategoryName || defaultCategoryName;
};
export const getComputedCategoryColour = cardData => {
    const eventType = cardData.get("event_type");
    const genreStripColor = eventType === "physical" ? colors.primaryBlue : eventType === "online" ? colors.primaryPink : colors.primaryDark;
    return genreStripColor;
};
export const getGroupFilters = createSelector([getCurrentElpData], homepageData => {
    return homepageData.getIn(["filters"]);
});
export const getGroupSorters = createSelector([getCurrentElpData], homepageData => {
    return homepageData.getIn(["sorters"]);
});
export const getGroupFilterBy = createSelector([getCurrentElpData], homepageData => {
    return homepageData.getIn(["filterBy"]);
});
export const getGroupName = (state, props) => {
    return props.group && props.group.get("name");
};
export const getFiltersForGroup = createSelector([getGroupFilters, getGroupName], (filters, groupName) => filters.get(groupName, Map()));
export const getShowFiltersForGroup = createSelector([getCurrentElpData], groupFilters => groupFilters.get("show"));
export const getGroupSortBy = createSelector([getCurrentElpData], homepageData => {
    return homepageData.getIn(["sortBy"]);
});
export const getGroupWiseList = createSelector([getCurrentHomepageData], homepageData => {
    return homepageData.getIn(["list", "groupwiseList"]);
});
export const getGroupWiseListForELP = createSelector([getCurrentElpData], homepageData => {
    return homepageData.getIn(["list", "groupwiseList"]);
});
export const getCategoryWiseList = createSelector([getCurrentHomepageData], homepageData => {
    return homepageData.getIn(["list", "categorywiseList"]);
});
export const getCategoryWiseListForELP = createSelector([getCurrentElpData], homepageData => {
    return homepageData.getIn(["list", "categorywiseList"]);
});
export const getCategoryFilterBy = state => {
    return state.getIn(["categories", "filterBy", "Category"]);
};
export const getCategorySortBy = state => {
    return state.getIn(["categories", "sortBy", "Category"]);
};
export const getCategoryTitle = (state, category) => {
    return state.getIn(["categories", "text", capitalizeFirstLetter(category), "seo_title"]);
};
export const getCategoryName = (state, props) => {
    if (props.category) {
        return props.category instanceof Map ? props.category.get("name") : props.category;
    }
    return null;
};
export const getMonumentsChosenCity = (state, props) => props.chosenCity;
export const getCardsForGroup = createSelector([getElpMasterList, getGroupWiseListForELP, getGroupName], (masterList, groupWiseList, groupName) => {
    // create a list of all group event objects from lookup slugs
    const slugsOfCards = groupWiseList.get(groupName) || new List();
    const cardsLookUp = slugsOfCards.reduce((result, slug) => {
        const event = masterList.get(slug);
        return result.push(event);
    }, new List([]));
    if (groupName === "Events") {
        const newCardsLookUp = masterList.reduce((result, event) => {
            return result.push(event);
        }, new List([]));
        return newCardsLookUp;
    }
    return cardsLookUp;
});
export const getCardsForCategory = createSelector([getElpMasterList, getCategoryWiseListForELP, getCategoryName], (masterList, categoryWiseList, categoryName) => {
    const slugsOfCards = categoryWiseList.get(categoryName) || new List();
    const cardsLookUp = slugsOfCards.reduce((result, slug) => {
        const event = masterList.get(slug);
        return result.push(event);
    }, new List([]));
    return cardsLookUp;
});
export const getGroupFilteredAndSortedCards = createSelector([
    getCardsForGroup,
    getGroupFilterBy,
    getSorterFromProps,
    getGroupName,
    getTimeFilter,
    getPriceFilter,
    getPriceRangeFilter
], (cards, filterBy, sortBy, groupName, timeFilter, priceFilter, maxPrice) => {
    return filterAndSortCards(cards, filterBy.get(groupName), sortBy, timeFilter, priceFilter, null, maxPrice);
});
export const getGroupFilteredAndTypeSortedCards = createSelector([
    getCardsForGroup,
    getGroupFilterBy,
    getSorterFromProps,
    getGroupName,
    getTimeFilter,
    getPriceFilter,
    getTypeFilter,
    getPriceRangeFilter
], (cards, filterBy, sortBy, groupName, timeFilter, priceFilter, typeFilter, maxPrice) => {
    return filterAndSortCards(cards, filterBy.get(groupName), sortBy, timeFilter, priceFilter, typeFilter, maxPrice);
});
export const getCardsForGroupAndCategory = createSelector([getGroupFilteredAndSortedCards, getCategoryFromProps], (cards, category) => {
    return category
        ? cards.filter(card => {
            return slugify(card.getIn(["category_id", "name"])) === encodeURIComponent(category);
        })
        : cards;
});
export const getTypeFilteredCardsForGroupAndCategory = createSelector([getGroupFilteredAndTypeSortedCards, getCategoryFromProps], (cards, category) => {
    return category
        ? cards.filter(card => {
            return slugify(card.getIn(["category_id", "name"])) === encodeURIComponent(category);
        })
        : cards;
});
export const getCardsForGroupAndCity = createSelector([getGroupFilteredAndSortedCards, getMonumentsChosenCity], (cards, chosenCity) => {
    return chosenCity && !chosenCity.includes("All Cities")
        ? cards.filter(card => card.get("city").includes(chosenCity))
        : cards;
});
export const getCardCountForGroupAndCategory = createSelector([getCardsForGroupAndCategory], cards => {
    return getCountFromCardData(cards);
});
export const getTypeFilteredCardCountForGroupAndCategory = createSelector([getTypeFilteredCardsForGroupAndCategory], cards => {
    return getCountFromCardData(cards);
});
const getCategoriesFromCards = cards => {
    const uniqueCategories = unique(cards.map(card => {
        return card.get("category_id");
    }));
    const categories = fromJS(uniqueCategories)
        .map(category => {
        const categoryName = category.get("name");
        return category.clear().merge(fromJS({
            model: "category",
            name: capitalizeFirstLetter(categoryName),
            eventCount: cards.filter(card => card.get("category_id").get("name") === categoryName).size,
            src: category.get("icon_img")
        }));
    })
        .sort(sortCategoriesByEventCount);
    return categories;
};
export const getCategoriesForGroupCards = createSelector([getGroupFilteredAndSortedCards], cards => {
    return getCategoriesFromCards(cards);
});
export const getCategoryNameFromSlug = createSelector([getCategoryFromProps, getCategoriesForRoutes], (category, categories) => {
    const foundCategory = category
        ? categories.find(cat => slugify(cat.get("name")) === encodeURIComponent(category))
        : null;
    return foundCategory ? foundCategory.get("name") : null;
});
export const getGroupFilteredCardCount = createSelector([getGroupFilteredAndSortedCards], cards => {
    return cards.size;
});
/**
 * For a given group, extract it's seo
 * information
 * @return {Immutable.Map}
 */
export const getSEODetailsForGroupAndCity = createSelector([getGroupFromProps, getCapitalizedSelectedCityName, getTimeFilter, getGroupFilteredCardCount], (group, city, timeFilter, cardCount) => {
    const groupName = capitalizeFirstLetter(group.get("name", ""));
    const defaultGroupTitle = `${"${count}"} ${groupName} in ${city} | Best Upcoming Events & Shows in Mumbai - insider.in`;
    const defaultGroupDescription = `Check out the best ${groupName} around you`;
    const description = group.getIn(["display_details", "seo_description"], defaultGroupDescription);
    return Map({
        title: replaceCityAndTimePlaceholder(defaultGroupTitle, city, timeFilter, cardCount),
        description: replaceCityAndTimePlaceholder(description, city, timeFilter, cardCount),
        displayTitle: groupName
    });
});
export const getCategoryFilteredAndSortedCards = createSelector([getCardsForCategory, getCategoryFilterBy, getSorterFromProps, getCategoryName, getTimeFilter, getPriceFilter], (cards, filterBy, sortBy, categoryName, timeFilter, priceFilter) => {
    return filterAndSortCards(cards, filterBy, sortBy, timeFilter, priceFilter);
});
export const getCategoryFilteredByTypeAndSortedCards = createSelector([
    getCardsForCategory,
    getCategoryFilterBy,
    getSorterFromProps,
    getCategoryName,
    getTimeFilter,
    getPriceFilter,
    getTypeFilter
], (cards, filterBy, sortBy, categoryName, timeFilter, priceFilter, typeFilter) => {
    return filterAndSortCards(cards, filterBy, sortBy, timeFilter, priceFilter, typeFilter);
});
export const getCategoryFilteredCardCount = createSelector([getCategoryFilteredAndSortedCards], cards => {
    return getCountFromCardData(cards);
});
export const getCategoryAndEventTypeFilteredCardCount = createSelector([getCategoryFilteredAndSortedCards], cards => {
    return getCountFromCardData(cards);
});
/**
 * For a given category, extract it's seo
 * information
 * @return {Immutable.Map}
 */
export const getSEODetailsForCategoryAndCity = createSelector([getCategoryFromProps, getCapitalizedSelectedCityName, getTimeFilter, getCategoryFilteredCardCount], (category, city, timeFilter, cardCount) => {
    const categoryName = capitalizeFirstLetter(category.get("name", ""));
    const defaultCategoryTitle = `Best Upcoming ${categoryName} Events & Shows in ${city} | Explore & Buy tickets online`;
    const defaultCategoryDescription = `Check out the best ${categoryName} around you`;
    const seoTitle = category.getIn(["display_details", "seo_title"], "");
    const defaultSeoTitlePattern = `${"${count}"} ${categoryName} events in ${"${city}"} ${"${time}"}. explore & buy tickets online`;
    const hasDefaultSeoTitle = !seoTitle || seoTitle.toLowerCase() === defaultSeoTitlePattern.toLowerCase();
    const title = hasDefaultSeoTitle ? defaultCategoryTitle : seoTitle;
    const description = category.getIn(["display_details", "seo_description"], defaultCategoryDescription);
    return Map({
        title: replaceCityAndTimePlaceholder(title, city, timeFilter, cardCount),
        description: replaceCityAndTimePlaceholder(description, city, timeFilter, cardCount),
        displayTitle: categoryName
    });
});
export const getEverythingFilteredAndSortedCards = createSelector([getElpMasterList, getSorterFromProps, getTimeFilter, getPriceFilter], (cards, sortBy, timeFilter, priceFilter) => {
    return filterAndSortCards(cards.toList(), null, sortBy, timeFilter, priceFilter);
});
export const getEverythingFilteredAndSortedCardsByCategory = createSelector([getElpMasterList, getSorterFromProps, getTimeFilter, getPriceFilter], (cards, sortBy, timeFilter, priceFilter) => {
    return filterAndSortCards(cards.toList(), null, sortBy, timeFilter, priceFilter);
});
export const getEverythingFilteredAndTypeSortedCards = createSelector([getElpMasterList, getSorterFromProps, getTimeFilter, getPriceFilter, getTypeFilterFromQueryParamProps], (cards, sortBy, timeFilter, priceFilter, typeFilter) => {
    return filterAndSortCards(cards.toList(), null, sortBy, timeFilter, priceFilter, typeFilter);
});
export const getEverythingCardCount = createSelector([getEverythingFilteredAndSortedCards], cards => {
    return getCountFromCardData(cards);
});
export const getEverythingTypeFilteredCardCount = createSelector([getEverythingFilteredAndTypeSortedCards], cards => {
    return getCountFromCardData(cards);
});
export const getEverythingTypeAndCategoryFilteredCardCount = createSelector([getEverythingFilteredAndSortedCards, getCategoryFromQueryParamProps], (cards, category) => {
    const categoryFilteredCards = cards.filter(card => card.get("category_id").get("name") === category);
    return getCountFromCardData(categoryFilteredCards);
});
/**
 * For a given city, extract it's seo
 * information
 * @return {Immutable.Map}
 */
export const getSEODetailsForCity = createSelector([getCapitalizedSelectedCityName, getTimeFilter, getEverythingCardCount], (city, timeFilter, cardCount) => {
    // used when no seo title or description is sent
    const title = "All Events in ${city} ${time} (Upcoming Events & Activities) insider.in";
    const defaultDescription = "Explore all the Events & Activities Happening in ${city} ${time}. Go for Trek, Comedy, Music, Workshops, Travel Events in ${city}";
    return Map({
        title: replaceCityAndTimePlaceholder(title, city, timeFilter, cardCount),
        description: replaceCityAndTimePlaceholder(defaultDescription, city, timeFilter, cardCount)
    });
});
export const getCategoriesForEverythingCards = createSelector([getEverythingFilteredAndSortedCards], cards => {
    return getCategoriesFromCards(cards);
});
export const getDates = createSelector([getHomepageData], homePageData => {
    return homePageData.get("go-out").get("dates");
});
export const getParentGroupOfCategory = createSelector([getCategoryWiseList, getElpMasterList, getCategoryName], (categoryWise, masterList, categoryName) => {
    const categoryGroupSlug = categoryWise.getIn([categoryName, 0]);
    if (categoryGroupSlug) {
        const categoryGroupEvent = masterList.get(categoryGroupSlug);
        const categoryGroup = categoryGroupEvent.get("group_id");
        return categoryGroup;
    }
    return null;
});
export const getClubs = state => state.get("clubs");
export const getClubData = createSelector([getClubs], clubs => {
    return clubs.get("clubData");
});
export const getClubTabs = createSelector([getClubData], club => {
    return club.getIn(["components"]).filter(component => {
        return component.get("type") === "tab";
    });
});
export const getClubDetails = createSelector([getClubData], club => {
    return {
        image: club.get("header_image"),
        name: club.get("title"),
        modalText: club.get("summary")
    };
});
export const getPlatform = state => state.getIn(["uiState", "platform"]);
export const isFromApp = createSelector([getPlatform], platform => {
    return platform === "android" || platform === "ios";
});
export const isSamsungDevice = createSelector([getPlatform], platform => {
    return platform === "samsungwallet";
});
export const isSamsungWalletSupported = createSelector([getUiState], uiState => uiState.get("isSamsungWalletDevice"));
export const getCart = state => state.getIn(["cartData"]);
export const getCartData = createSelector([getCart], cart => {
    return cart.get("cart");
});
export const getEmptyCartData = createSelector([getCart], cart => {
    return cart.get("emptyCart");
});
export const getCartDataLoading = createSelector([getCart], cart => {
    return cart.get("requestingCartData");
});
export const getPayableCost = createSelector([getCart], cart => {
    return cart.getIn(["cart", "payable_cost"]);
});
export const getBannersForGroupPage = createSelector([getELPBanners, getGroupName], (banners, groupName) => {
    return banners.filter(b => b.getIn(["group_id", "name"]) === groupName);
});
export const getAllCitiesForGroup = createSelector(getCardsForGroup, cards => {
    let allCities = Array.from(new Set(cards.map(card => card.get("city").trim()).sort()));
    allCities.unshift("All Cities");
    return allCities;
});
export const getEventsForHomePageGroupCarousel = createSelector([getMasterList, getGroupWiseList], (masterList, groupWiseList) => {
    // create a list of all group event objects from lookup slugs
    const slugsOfCards = groupWiseList.get(HomePageGroupCarouselConstants.groupName) || new List();
    const cardsLookUp = slugsOfCards.reduce((result, slug) => {
        const event = masterList.get(slug);
        return result.push(event);
    }, new List([]));
    return filterAndSortCards(cardsLookUp);
});
export const getUserToken = createSelector([getUserData], user => user.getIn(["profile", "user_token"]));
export const getUserProfileImage = createSelector([getUserData], user => user.getIn(["profile", "profile_image"]));
export const getLockBodyScroll = state => state.getIn(["uiState", "lockBodyScroll"]);
export const getDisableSocialLoginFlag = state => state.getIn(["uiState", "disableSocialLogin"]);
const getQuickSearchFilters = state => state.getIn(["searchData", "quickSearchFilters"]);
const filterHavingPopularEvents = (quickFilters, masterList) => {
    const quickFiltersHavingPopularEvents = [];
    quickFilters.forEach(filter => {
        if (masterList.some(event => {
            if (event.get("popularity_score") > 0) {
                if (filter.get("type") === "categoryFilter" && event.get("category_id").get("_id") === filter.get("value")) {
                    return true;
                }
                else if (filter.get("type") === "priceFilter" && event.get("min_price") <= filter.get("value")) {
                    return true;
                }
                else if (filter.get("type") === "timeFilter" &&
                    event.get("applicable_filters").includes(filter.get("value"))) {
                    return true;
                }
                else if (filter.get("type") === "tagFilter") {
                    return event.get("tags").some(tag => {
                        if (tag.get("tag_id") === filter.get("value"))
                            return true;
                    });
                }
                else if (filter.get("type") === "groupFilter" && event.get("group_id").get("_id") === filter.get("value")) {
                    return true;
                }
                return false;
            }
            else
                return false;
        }))
            quickFiltersHavingPopularEvents.push(filter);
    });
    return quickFiltersHavingPopularEvents;
};
export const getQuickSearchFiltersFromConfig = createSelector([getQuickSearchFilters, getElpMasterList], (quickFilters, masterList) => filterHavingPopularEvents(quickFilters, masterList));
export const getFilterApplied = state => state.getIn(["searchData", "filterApplied"]);
export const getIsCityChanged = state => state.get("cityChanged").get("isCityChanged"); //state.getIn(['cityChanged','isCityChanged'])//
