import { connect } from "react-redux";
// components
import ISLLayout from "../components/ISL/Layout/ISLLayout";
import { isFromApp } from "../selectors";
const mapStateToProps = (state) => ({
    isFromApp: isFromApp(state),
});
const mapDispatchToProps = (dispatch) => ({});
const ISLLayoutWithSubscription = connect(mapStateToProps, mapDispatchToProps)(ISLLayout);
// set static on this component
// for its preload chunk
export default ISLLayoutWithSubscription;
