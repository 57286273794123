// Load promise polyfill for everyone so that we can load polyfills conditionally
require("es6-promise");
const { isPaytm } = require("../../lib/paytm");
const polyfills = [];
const supportsEs6 = window.Symbol && Object.hasOwnProperty("assign") && Array.prototype.includes;
const hasIntersectionSupport = "IntersectionObserver" in global &&
    "IntersectionObserverEntry" in global &&
    "intersectionRatio" in IntersectionObserverEntry.prototype;
const hasMutationObserverSupport = "MutationObserver" in global;
if (!supportsEs6) {
    polyfills.push(import(/* webpackChunkName: "es6" */ "babel-polyfill"));
}
if (!window.fetch) {
    polyfills.push(import(/* webpackChunkName: "fetch" */ "whatwg-fetch"));
}
if (!window.Intl) {
    polyfills.push(import(/* webpackChunkName: "internationalization" */ "./polyfills/internationalization.js"));
}
if (!hasIntersectionSupport) {
    polyfills.push(import(/* webpackChunkName: "IntersectionObserver" */ "intersection-observer"));
}
if (!hasMutationObserverSupport) {
    polyfills.push(import(/* webpackChunkName: "MutationObserver" */ "mutation-observer"));
}
if (!window.requestAnimationFrame) {
    polyfills.push(import(/* webpackChunkName: "RequestAnimationFrame" */ "raf/polyfill"));
}
export const main = () => {
    // Put the git commit hash on the window object, so we can use it for logging and debugging.
    // This is defined in the Webpack config.
    if (window && typeof __COMMIT_HASH__ !== "undefined") {
        window.__COMMIT_HASH__ = __COMMIT_HASH__;
    }
    const { dispatchInitialActions } = require("./initial-actions");
    // Framework imports
    const React = require("react");
    const { reactInitialRender } = require("./helpers/initialRender");
    const { Provider } = require("react-redux");
    const { getRoutes, handleRouterUpdate } = require("./routers/createInsiderAppRoutes");
    const createInsiderStore = require("./store").default;
    const { applyRouterMiddleware, match, Router, browserHistory } = require("react-router");
    // const { useScroll } = require("react-router-scroll");
    const queryString = require("query-string");
    const getInitialState = require("./getInitialState").default;
    const { initialiseFBTracking } = require("./tracking");
    const { getGroupsAndCategories } = require("./helpers/getGroupsCategories");
    const { getPersistedSelectedCity, setPersistedSelectedCity } = require("./helpers/selectedCity");
    const { getShouldShowCitySelector } = require("./helpers/getShouldShowCitySelector");
    const { getLocation, getClosestCity } = require("./helpers/geolocation");
    const { promiseTimeout } = require("./helpers/misc");
    const { updatedCityName, allowNewIframeRedirectMerchantId, allowedIframeOrigins } = require("./helpers/constants/common");
    const { sendClevertapEvent } = require("./helpers/misc");
    const { CLEVERTAP_ACTIONS } = require("./helpers/constants");
    const cookies = require("browser-cookies");
    const ErrorBoundary = require("./components/ErrorBoundary").default;
    const getAllCities = require("./selectors").getAllCities;
    const fetchCities = require("./actions/index").fetchCities;
    const shouldLoadReact = () => {
        const params = queryString.parse(window.location.search.toLowerCase());
        return !("disable_react" in params);
    };
    /**
     * Checks if any JS object or Immutable is empty or not
     * @param {any | Immutable.Collection<any, any>} data Value to be checked
     * @return {boolean} isEmpty
     */
    const isEmpty = data => {
        if (!data)
            return true;
        if (data && data.length)
            return data.length === 0;
        if (data && data.isEmpty)
            return data.isEmpty();
        return false;
    };
    /**
     * Data is either sync fetched from the store using the selector
     * or the action creator for that data is called, followed by calling
     * the selector to extract the data out
     * @param {} opts
     * @param {Immutable.Map<any, any>} opts.state
     * @param {Function} opts.selector
     * @param {Function} opts.actionCreator
     */
    const getDataFromStoreOrBackend = ({ store, state, selector, actionCreator }) => {
        // data source can be store or API call
        // cities can come from either state, or from API call
        // but we want to resuse the action creator + reducer here
        const dataFromStore = selector(state);
        // data can be js or immutable
        if (isEmpty(dataFromStore)) {
            // we don't know if actionCreator will return promise
            // or not, hence we do this inside a promise
            // todo add support to call actionCreator with args
            return Promise.resolve(store.dispatch(actionCreator())).then(() => {
                // store has been updated
                const updatedState = store.getState();
                // now call it recursively to apply selector on it and get the data out
                return selector(updatedState);
            });
        }
        return Promise.resolve(dataFromStore);
    };
    const getSelectedCity = cities => {
        const path = window.location.pathname;
        // We try to get the city from the following sources
        // 1. Persistent store (cookies / H5 bridge)
        // 2. If you are visting `/{city-name}`
        // 3. If you are directly visiting a route derived from the city name
        // eg - `/events-in-mumbai`
        return getPersistedSelectedCity().then(currentCachedCity => {
            const cachedCity = currentCachedCity ? updatedCityName(currentCachedCity) : "";
            if (cachedCity) {
                // reset cookie in case its a session cookie
                setPersistedSelectedCity(cachedCity);
            }
            // if you do have a saved city, but you are visiting
            // a not root URL, we need to check if that URL overrides
            // your saved city
            // For eg, if your selected city is mumbai
            // But you directly visit `events-in-delhi`, your selected
            // city for that session should be delhi
            // If you are visiting the root URL, we can shortcircuit
            // and immediately return cached city
            if (path === "/" && cachedCity) {
                return cachedCity;
            }
            // Otherwise find the city from the URL
            let selectedCity;
            for (const city of cities) {
                const cityName = city.get("name").toLowerCase();
                const isCityHomePage = path === `/${cityName}`;
                const [emptyString, slug, afterSlash] = path.split("/");
                const isCityGroupOrCategoryPage = slug.endsWith(`-in-${cityName}`) && !["article", "event"].includes(afterSlash);
                // Regex: https://www.debuggex.com/r/oELE6aUzon6MbdeR
                const reg = new RegExp(`.+-in-${cityName}-(today|tomorrow|weekend)`);
                const isCityTimeBasedPage = slug.match(reg) && !["article", "event"].includes(afterSlash);
                if (isCityHomePage || isCityGroupOrCategoryPage || isCityTimeBasedPage) {
                    selectedCity = cityName;
                    break;
                }
            }
            // If we do have a cached city
            // And the URL is not "/"
            // And we couldn't infer the city
            // from the URL, then we should fallback
            // to the cached city
            if (cachedCity && !selectedCity) {
                return cachedCity;
            }
            // if we couldn't figure out the city from either cache
            // or from URL, we try for location
            if (!selectedCity) {
                return promiseTimeout(getLocation(), 2000)
                    .then(result => {
                    if (result) {
                        return getClosestCity(result, cities, 50);
                    }
                    return Promise.resolve("online");
                })
                    .catch(err => {
                    console.warn(err);
                    return selectedCity;
                });
            }
            return selectedCity;
        });
    };
    /**
     * This function does all the initial
     * client side scaffolding
     * 1. Create our redux store, and rehydrate it
     * 2. Use the browserHistory since we're using URLs and not hashes
     * 3. Figure out the current city of the user - this comes from the rehydrated store
     * 4. Call `match` from react-router, which will run the callback after all async routes are loaded
     * 5. Render
     * 6. Profit!
     */
    function initialiseApp() {
        initialiseFBTracking();
        const initialRootState = getInitialState();
        const store = createInsiderStore(initialRootState);
        const history = browserHistory;
        const state = store.getState();
        if (!shouldLoadReact()) {
            return;
        }
        // Add support to open Insider links on Paytm App
        const query = queryString.parse(window.location.search);
        if (["h5.insider.in", "h5new.insider.in"].includes(window.location.host) && query["__h5_universal_link__"]) {
            const redirectURL = new URL(query["__h5_universal_link__"]);
            if (redirectURL.host.includes("insider.in")) {
                const h5UniversalLink = redirectURL.pathname + redirectURL.search;
                sendClevertapEvent(CLEVERTAP_ACTIONS.REDIRECTED_TO_H5_USING_INSIDER_LINK, {
                    redirectURL: h5UniversalLink
                });
                window.location.href = h5UniversalLink;
            }
        }
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const UTM_PARAMS = ["utm_source", "utm_campaign", "utm_content", "utm_medium"];
        const EMBED_PARAMS = ["embed", "merchantId", "origin"];
        const merchantId = searchParams.get("merchantId");
        const origin = searchParams.get("origin");
        for (const [key, val] of searchParams) {
            if (key === "embed" &&
                merchantId &&
                allowNewIframeRedirectMerchantId.includes(merchantId) &&
                allowedIframeOrigins.includes(origin)) {
                if (typeof document !== "undefined" && typeof navigator !== "undefined") {
                    document.cookie = "embed=true; SameSite=None; Secure; path=/;";
                }
            }
            if (val && UTM_PARAMS.includes(key)) {
                cookies.set(key, val, { secure: true, expires: 14 });
            }
        }
        // fetch all cities
        const allCitiesPromise = getDataFromStoreOrBackend({
            store,
            state,
            selector: getAllCities,
            actionCreator: fetchCities
        });
        // calculate selected city
        const selectedCityPromise = allCitiesPromise.then(getSelectedCity);
        // persist selected city
        const saveSelectedCityPromise = selectedCityPromise.then(selectedCity => {
            if (selectedCity)
                return setPersistedSelectedCity(selectedCity);
        });
        const path = window.location.pathname;
        // dispatch initial actions after everything
        const initialActionsPromise = selectedCityPromise.then(selectedCity => {
            const DEFAULT_CITY = "online";
            if (!selectedCity) {
                selectedCity = DEFAULT_CITY;
            }
            const shouldShowCitySelector = getShouldShowCitySelector(path, selectedCity);
            // for our initial hydration plus route composition
            // we need a default city value, even though this won't actually be shown
            // to the user
            // Since selectedCityPromise can resolve with null,
            // we need to check for that
            const safeSelectedCity = selectedCity || DEFAULT_CITY;
            const isInferredCity = Boolean(selectedCity);
            dispatchInitialActions(store, safeSelectedCity, shouldShowCitySelector, isInferredCity);
            return safeSelectedCity;
        });
        // Fetch groups and categories data to calculate routes
        const groupsAndCategoriesPromise = initialActionsPromise.then(() => getGroupsAndCategories(state));
        Promise.all([initialActionsPromise, groupsAndCategoriesPromise, saveSelectedCityPromise]).then(([selectedCity, data, _]) => {
            // Construct all the possible routes
            const routes = getRoutes(selectedCity, data.get("groups"), data.get("category"));
            const rootNode = document.getElementById("there-you-go");
            match({ routes, location, history }, (error, redirectLocation, renderProps) => {
                const { hideLoader } = require("./helpers/misc");
                hideLoader();
                reactInitialRender(<Provider store={store}>
							<ErrorBoundary>
								<Router {...renderProps} onUpdate={handleRouterUpdate} /*render={applyRouterMiddleware(useScroll())}*//>
							</ErrorBoundary>
						</Provider>, rootNode);
            });
        });
    }
    initialiseApp();
};
Promise.all(polyfills)
    .then(main)
    .catch(e => console.error(e));
