import { postToSite, postFormToSite } from "./common";
import { 
// getAppInfo,
getDeviceManufacturer, getOS } from "../h5";
import { memoize } from "../inMemoryCache";
import { setPlatformFromSession } from "../misc";
const getRefSource = memoize(async () => {
    if (typeof window !== "undefined" && typeof window.JSBridge !== "undefined") {
        const os = await getOS();
        const manufacturer = await getDeviceManufacturer();
        // const { appVersion = '', h5Version = '', appVersionName = '' } = await getAppInfo();
        // ios sends appVersion and android appVersionName
        return `${os}|${manufacturer}`;
    }
    else
        return 'insider.in';
});
export const postFormToSiteWithEncryptedSsoToken = async function (resource, formData) {
    if (formData) {
        formData.device_platform = setPlatformFromSession();
        formData.refSource = await getRefSource();
    }
    return postFormToSite(resource, formData);
};
export const postToSiteWithEncryptedSsoToken = async function (resource, body) {
    if (body) {
        body.device_platform = setPlatformFromSession();
        body.refSource = await getRefSource();
    }
    const stringifiedBody = body ? JSON.stringify(body) : "";
    return postToSite(resource, stringifiedBody);
};
export * from "./common";
