/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import ISLHeader from '../ISLComponents/ISLHeader';
import ISLFooter from '../ISLComponents/ISLFooter';
import { colors, typography } from '../../../styles/common';
const ISLLayout = (props) => {
    var _a, _b;
    return (<div css={css([typography.chromeBody, layoutStyle])} style={{ background: colors.greyBackground }}>
        <ISLHeader isFromApp={(_a = props === null || props === void 0 ? void 0 : props.isFromApp) !== null && _a !== void 0 ? _a : false}/>
        {props.children}
        <ISLFooter isFromApp={(_b = props === null || props === void 0 ? void 0 : props.isFromApp) !== null && _b !== void 0 ? _b : false}/>
    </div>);
};
export default ISLLayout;
const layoutStyle = `
    padding-bottom: 15px;
    margin-bottom: 30px;
    * {
        scroll-behavior: smooth;
    }
`;
