import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
// components
import ConditionalComponentRenderer from "../components/ConditionalComponentRenderer";
// actions
import { getSearchBarInView, getSelectedCityName } from "../selectors/index";
import withInlineSearchBoolean from "../components/withInlineSearchBoolean";
const mapStateToProps = (state, ownProps) => {
    return {
        searchBarInView: getSearchBarInView(state),
        selectedCity: getSelectedCityName(state),
        showOnDesktop: ownProps.showOnDesktop || false
    };
};
const SearchViewTogglerWithSubscription = (props) => {
    const ShouldShowInlineSearch = 
    // add redux props
    connect(mapStateToProps)(
    // add location props
    withRouter(
    // add inline search boolean
    withInlineSearchBoolean(props)(ConditionalComponentRenderer)));
    return <ShouldShowInlineSearch />;
};
export default SearchViewTogglerWithSubscription;
