import { webBlacklistTagId } from "../constants";
import { checkPhoenixViaUserAgent } from "../appBridge";
import { share } from "../h5";
export const hideLoader = () => { };
export const getDeviceInfo = () => {
    return new Promise(resolve => {
        resolve({ os: "", version: "" });
    });
};
/**
 * Invokes the native sharesheet on h5 pages (except paytm) via [paytmShareTextAndImage](../h5/index.ts) JSBridge.
 * On other platforms, this invokes the window's share (if present) else displays a fallback sharesheet.
 * This fallback is also displayed in case of failure of the above JSBridge.
 */
export const nativeShare = ({ text, url, imageUrl, onSuccess, onError, fallback }) => {
    var _a, _b;
    const navigator = window.navigator;
    const shareObject = {
        text: text + " : " + url,
        title: "Share this event! The more the merrier, right?",
        subject: "",
        imageBase64: (_b = (_a = window === null || window === void 0 ? void 0 : window.Buffer) === null || _a === void 0 ? void 0 : _a.from(imageUrl)) === null || _b === void 0 ? void 0 : _b.toString("base64")
    };
    if (checkPhoenixViaUserAgent()) {
        share(shareObject)
            .then((result) => {
            if (!result || (result && !result.data.success))
                fallback && fallback();
        })
            .catch(() => {
            fallback && fallback();
        });
    }
    else if (navigator.share) {
        return navigator
            .share({ text, url })
            .then(onSuccess)
            .catch(onError);
    }
    else {
        fallback && fallback();
    }
};
export const stripHrefDomain = (href, isFromApp, platform) => {
    const sources = ["https://insider.in", "http://insider.in", "http://new.insider.in"];
    const queryParamsExist = href === null || href === void 0 ? void 0 : href.includes("?");
    for (const source of sources) {
        if (typeof href !== "undefined" && href.startsWith(source)) {
            if (isFromApp) {
                return queryParamsExist
                    ? `${href.substring(source.length)}&platform=${platform}`
                    : `${href.substring(source.length)}?platform=${platform}`;
            }
            return href.substring(source.length);
        }
    }
    return href;
};
export function getAlgoliaEventSearchFilters(cityTagId) {
    const date = Math.floor(Date.now() * 0.001);
    return [
        `endTime > ${date}`,
        `AND (NOT tagids:${webBlacklistTagId} ) `,
        `AND ( tagids:${cityTagId}<score=1> OR NOT tagids:${cityTagId}<score=0> )`
    ].join(" ");
}
export function getAlgoliaArticleSearchFilters() {
    return [`( NOT tagids:${webBlacklistTagId} )`].join(" ");
}
export const loginWithPaytm = (_referrer) => Promise.resolve();
export const isLoggedInOnPaytm = (_customerId) => false;
export function navigateToPayment(_paymentData) { }
export const getCartCommittedScreen = (paymentOptions, _checkoutWizard) => paymentOptions;
export const getPromoCodesIfRequired = (_props, _prevProps) => { };
export const generateH5Email = (_customerId) => "";
export const isH5Build = false;
export const getNetworkInformation = () => {
    // @ts-ignore its because we have an older version of ts..it doesn't know about navigator.connection
    const connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection || null;
    let connectionType = (connection && connection.type) || "unknown";
    let connectionEffectiveType = (connection && connection.effectiveType) || "unknown";
    return {
        connectionType,
        connectionEffectiveType
    };
};
export const setPlatformFromSession = () => {
    let platform = "";
    if (typeof window !== "undefined" && typeof window.sessionStorage !== "undefined") {
        platform = window.sessionStorage.getItem("platformKey") || "";
    }
    switch (platform) {
        case "android":
            return "android";
        case "ios":
            return "ios";
        case "samsungwallet":
            return "samsungwallet";
        default:
            return "web";
    }
};
export * from "./common";
export * from "./clevertap";
