export const data = {
    'headerTitle': 'Mumbai Festival 2024',
    'navUrl': '/mumbai-festival-2024',
    'metaTitle': 'Mumbai Festival 2024 | insider.in',
    'schedulePageMetaTitle': 'Events at Mumbai Festival 2024 | insider.in',
    'heroSection': {
        'img': '/assets/images/mumbaiFest/heroWeb.png',
        'mImg': '/assets/images/mumbaiFest/heroMob.png',
    },
    'eventCardTag': 'mumbai-festival-featured',
    'categories': [
        {
            'img': '/assets/images/mumbaiFest/culture.svg',
            'title': 'Culture',
            'id': 'mumbai-festival-culture'
        },
        {
            'img': '/assets/images/mumbaiFest/sports.svg',
            'title': 'Sports',
            'id': 'mumbai-festival-sports'
        },
        {
            'img': '/assets/images/mumbaiFest/movie.svg',
            'title': 'Entertainment',
            'id': 'mumbai-festival-entertainment'
        },
        {
            'img': '/assets/images/mumbaiFest/travel.svg',
            'title': 'Tourism',
            'id': 'mumbai-festival-tourism'
        },
        {
            'img': '/assets/images/mumbaiFest/shopping.svg',
            'title': 'Shopping',
            'id': 'mumbai-festival-shopping'
        },
        {
            'img': '/assets/images/mumbaiFest/flames.svg',
            'title': 'Sustainability',
            'id': 'mumbai-festival-sustainability'
        }
    ],
    'categoryFilters': [
        {
            'id': 'mumbai-festival-culture',
            'display': 'Culture'
        },
        {
            'id': 'mumbai-festival-sports',
            'display': 'Sports'
        },
        {
            'id': 'mumbai-festival-entertainment',
            'display': 'Entertainment'
        },
        {
            'id': 'mumbai-festival-tourism',
            'display': 'Tourism'
        },
        {
            'id': 'mumbai-festival-shopping',
            'display': 'Shopping'
        },
        {
            'id': 'mumbai-festival-sustainability',
            'display': 'Sustainability'
        }
    ],
    'aboutFestival': {
        'gallery': ['/assets/images/mumbaiFest/gallery1.jpg', '/assets/images/mumbaiFest/gallery2.jpg', '/assets/images/mumbaiFest/gallery3.jpg'],
        'content': "The Mumbai Festival, in its inaugural edition, emerges as a grand celebration spanning nine days, taking center stage across diverse locations in the heart of Mumbai. With a mission to showcase the city's cultural kaleidoscope, the festival brings together a tapestry of events, performances, and experiences.\n \nSpearheaded by the dynamic Mr. Anand Mahindra, serving as the Chairperson of the Mumbai Festival Advisory Committee, the cultural phenomena aims to capture the essence of Mumbai's rich heritage and spirit.\n \nThis festival promises to become an iconic annual celebration, uniting communities and celebrating the vibrant cultural mosaic that defines the city of Mumbai.",
    },
    'festivalVideoLink': 'https://www.youtube.com/embed/hPabAOGh6lU?transparent=0',
    'participatingBrands': ['/assets/images/mumbaiFest/hdfc.svg'],
    'partners': {
        'initiative': [
            '/assets/images/mumbaiFest/mahaTourism.png'
        ],
        'manageBy': [
            '/assets/images/mumbaiFest/wizcraft.jpg'
        ],
        'sponserPartner': [
            {
                'img': '/assets/images/mumbaiFest/tataPower.png',
                'subTitle': 'Mumbai Partners'
            },
            {
                'img': '/assets/images/mumbaiFest/ongc.png',
                'subTitle': 'Mumbai Partners'
            },
            {
                'img': '/assets/images/mumbaiFest/sbi.png',
                'subTitle': 'Banking Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/bpcl.png',
                'subTitle': 'Progress Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/signpost.png',
                'subTitle': 'Strategic Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/areion.png',
                'subTitle': 'Inclusivity Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/easemytrip.png',
                'subTitle': 'Travel Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/bisleri.png',
                'subTitle': 'Hydration Partner'
            },
        ],
        'others': [
            {
                'img': '/assets/images/mumbaiFest/toi.png',
                'subTitle': 'Festival Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/mahaTimes.png',
                'subTitle': 'Festival Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/nbt.png',
                'subTitle': 'Festival Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/tsg.png',
                'subTitle': 'Media Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/newsX.png',
                'subTitle': 'Media Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/abp.png',
                'subTitle': 'Media Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/mirchi.png',
                'subTitle': 'Media Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/redFm.png',
                'subTitle': 'Media Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/pvr.png',
                'subTitle': 'Media Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/dailyhunt.png',
                'subTitle': 'Media Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/mtdc.png',
                'subTitle': 'Supported by'
            },
            {
                'img': '/assets/images/mumbaiFest/UN.png',
                'subTitle': 'Sustainability Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/projectMum.png',
                'subTitle': 'Sustainability Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/social.png',
                'subTitle': 'Experience Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/insiderPaytm.png',
                'subTitle': 'Ticketing Partner'
            },
            {
                'img': '/assets/images/mumbaiFest/barcode.png',
                'subTitle': 'Influencer Partner'
            }
        ]
    },
    'sponsers': {
        '1': null
    },
    'faqId': '88000175659',
    'SBIMumbaiAd': '/assets/images/mumbaiFest/SBIMumbaiFestivalAd.jpg',
};
