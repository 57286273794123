/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState } from 'react';
import { ArrowDownIcon } from "../../ui/icons/ArrowDownIcon";
import { ArrowUpIcon } from "../../ui/icons/ArrowUpIcon";
import { typography, colors, smallDevice } from "../../../styles/common";
const DynamicFaqsList = ({ title = 'Faqs', queKey = '', ansKey = '', isAnsHtml = false, list = [], filter, customStyle = {}, fillColor }) => {
    const [selectedItem, setSelectedItem] = useState(-1);
    return (<div>
      <h6 css={css([footerHeading, customStyle === null || customStyle === void 0 ? void 0 : customStyle.header])}>{title}</h6>
      {(list.slice(0, filter !== null && filter !== void 0 ? filter : list.length)).map((item, idx) => <div key={idx} css={css([faqItemContainer, customStyle === null || customStyle === void 0 ? void 0 : customStyle.itemContainer])} onClick={() => selectedItem !== idx ? setSelectedItem(idx) : setSelectedItem(-1)}>
          <div css={css([itemTitleCont, customStyle === null || customStyle === void 0 ? void 0 : customStyle.itemTitleContainer])}>
            <h6 css={css([itemTitle, customStyle === null || customStyle === void 0 ? void 0 : customStyle.itemTitle])}>{queKey ? item[queKey] : item}</h6>
            {selectedItem === idx ? <ArrowUpIcon overideStyle={{ height: '16px', width: '16px' }} fillColor={fillColor}/> : <ArrowDownIcon overideStyle={{ height: '16px', width: '16px' }} fillColor={fillColor}/>}
          </div>
          {selectedItem === idx && <React.Fragment>
            <hr />
            {isAnsHtml ? <div css={css([htmlAsnConnt, customStyle === null || customStyle === void 0 ? void 0 : customStyle.subTitle])} dangerouslySetInnerHTML={{ __html: ansKey ? item[ansKey] : item }}/> : <p css={css([itemSub, customStyle === null || customStyle === void 0 ? void 0 : customStyle.subTitle])}>{ansKey ? item[ansKey] : item}</p>}
          </React.Fragment>}
        </div>)}
    </div>);
};
export default DynamicFaqsList;
const footerHeading = `
  font-family: ${typography.shinyHeader5["font-family"]};
  font-size: 28px;
  font-weight: 400;
  color: ${colors.darkBlueBg};
`;
const faqItemContainer = `
  background: ${colors.white};
  border-radius: 12px;
  padding: 12px;
  margin: 15px 0;
  border: 1px solid ${colors.faintGrey};
  font-family: ${typography.chromeBody["font-family"]};
  cursor: pointer;
  hr {
    height: 2px;
    width: 100%;
    border: 0;
    margin: 12px 0;
    background: ${colors.faintGrey};
  }
`;
const itemTitleCont = `
  display: flex;
  justify-content: space-between;
  align-items: center;
  p, a {
    font-size: 16px;
    font-weight: 500;
    a {
      text-decoration: underline !important;
    }
    @media only screen and (max-width:${smallDevice}em){ 
      font-size: 14px;
    }
  }
`;
const itemTitle = `
  margin-right: 12px;
  flex: 1;
  font-size: 16px;
  font-weight: 700;

  @media only screen and (max-width:${smallDevice}em){ 
    font-size: 14px;
  }
`;
const itemSub = `
  font-size: 13px;
  font-weight: 500;

  @media only screen and (max-width:${smallDevice}em){ 
    font-size: 12px;
  }
`;
const htmlAsnConnt = `
  p, a {
    font-size: 16px;
    font-weight: 500;
    @media only screen and (max-width:${smallDevice}em){ 
      font-size: 14px;
    }
  }
  a {
    text-decoration: underline !important;
  }
`;
