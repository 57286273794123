/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import InsiderLink from "../../../containers/InsiderLinkWithSubscription";
import { CrossIcon } from "../../ui/icons/CrossIcon";
import { colors, mediaFeatures, typography } from "../../../styles/common";
import { MenuIcon } from "../../ui/icons/MenuIcon";
import { isH5Build } from "../../../helpers/misc";
const DynamicHeader = ({ location, homeLink = '', title = '', logoImg = '', isFromApp = false, isSpa = true, navRoute = [], customStyle = { bgColor: colors.descriptionColor, navItemColor: colors.white, navActiveColor: colors.white, logoHeight: '45px', headerShadow: 'none', logoBorderRadius: '6px' } }) => {
    var _a, _b, _c, _d, _e, _f;
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [landingProp, setLandingProp] = useState('');
    const [activeSection, setActiveSection] = useState(null);
    const sections = useRef([]);
    const headerHeight = 55;
    const headerStyleConfig = {
        bgColor: (_a = customStyle === null || customStyle === void 0 ? void 0 : customStyle.bgColor) !== null && _a !== void 0 ? _a : colors.blackShadow,
        navActiveColor: (_b = customStyle === null || customStyle === void 0 ? void 0 : customStyle.navActiveColor) !== null && _b !== void 0 ? _b : colors.white,
        navItemColor: (_c = customStyle === null || customStyle === void 0 ? void 0 : customStyle.navItemColor) !== null && _c !== void 0 ? _c : colors.white,
        logoHeight: (_d = customStyle === null || customStyle === void 0 ? void 0 : customStyle.logoHeight) !== null && _d !== void 0 ? _d : '45px',
        headerShadow: (_e = customStyle === null || customStyle === void 0 ? void 0 : customStyle.headerShadow) !== null && _e !== void 0 ? _e : 'none',
        logoBorderRadius: (_f = customStyle === null || customStyle === void 0 ? void 0 : customStyle.logoBorderRadius) !== null && _f !== void 0 ? _f : '6px'
    };
    const handleMenuItemClick = useCallback((e, id) => {
        e.preventDefault();
        const allSection = document.querySelectorAll('[data-section]');
        allSection.forEach((item) => {
            if (item.id === id) {
                const offsetTop = item.offsetTop - headerHeight;
                if (window) {
                    window.scrollTo({
                        top: offsetTop,
                        behavior: "smooth"
                    });
                }
            }
        });
    }, [landingProp]);
    const listenScrollEvent = useCallback((e) => {
        if (window.scrollY > 62) {
            setLandingProp(fixedHeader);
        }
        else
            setLandingProp('');
    }, [window]);
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
    }, [listenScrollEvent]);
    const getRouteJsx = useCallback((item, isMobile) => {
        let currentPath = location === null || location === void 0 ? void 0 : location.pathname;
        const active = isSpa ? activeSection : currentPath;
        const navCss = isMobile ? mobileNavItem(headerStyleConfig.navActiveColor, active === item.sectionId) : navItem(headerStyleConfig.navActiveColor, active === item.sectionId);
        return (isSpa ? <a key={item.title}>
                <div css={css([navCss])} onClick={(e) => {
                if (isMobile)
                    setOpenMobileMenu(false);
                e.preventDefault();
                setTimeout(() => handleMenuItemClick(e, item.sectionId), 300);
                // sendEvent(item?.eventName ?? '')
            }}>
                    <p>{item.title}</p>
                </div>
            </a> :
            <InsiderLink key={item.title} href={item.link}>
                <div css={css([navCss])} onClick={(e) => {
                    if (isMobile)
                        setOpenMobileMenu(false);
                    setActiveSection(item.sectionId);
                }}>
                    <p>{item.title}</p>
                </div>
             </InsiderLink>);
    }, [isH5Build, activeSection, landingProp, handleMenuItemClick, location]);
    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;
        sections.current.forEach((section) => {
            const sectionOffsetTop = section.offsetTop - headerHeight;
            const sectionHeight = section.offsetHeight;
            if (pageYOffset >= sectionOffsetTop && pageYOffset < sectionOffsetTop + sectionHeight) {
                newActiveSection = section.id;
            }
        });
        setActiveSection(newActiveSection);
    };
    useEffect(() => {
        if (isSpa) {
            sections.current = document.querySelectorAll('[data-section]');
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);
    return (<div css={css([headerContainer(headerStyleConfig.bgColor, headerStyleConfig.navItemColor, headerStyleConfig.headerShadow), absoluteTransparentHeader, landingProp])}>
            <div css={css([webNavCont(headerStyleConfig.bgColor, openMobileMenu)])}>
                <InsiderLink href={homeLink}>
                    <div css={css([logoCont(headerStyleConfig.logoHeight, headerStyleConfig.logoBorderRadius)])}>
                        {logoImg ? <img src={logoImg} alt="logo"/> : null}
                        {title && <span>{title}</span>}
                    </div>
                </InsiderLink>
                <div css={css([navItemCont, HideWebNav])}>
                    {navRoute.map((item) => getRouteJsx(item, false))}
                </div>
                <div css={css([`display: none;`, showMobileNav])}>
                    <div css={css([mobileNavItem(headerStyleConfig.navActiveColor)])} onClick={() => {
            setOpenMobileMenu(!openMobileMenu);
        }}> 
                        {openMobileMenu ? <CrossIcon strokeColor={colors.white} backgroundColor="transparent"/> : <MenuIcon overideStyle={{ 'transform': 'rotateY(180deg)' }}/>}
                    </div>
                </div>
            </div>
            {openMobileMenu &&
            <div css={css([mobileNavItemCont])}>
                    <div css={css([horizontalLine])}/>
                    {navRoute.map((item) => getRouteJsx(item, true))}
                </div>}
        </div>);
};
export default withRouter(React.memo(DynamicHeader));
const headerContainer = (bgColor, navItemColor, headerShadow) => `
    transition: all 0.1s ease-in-out;
    width: 100%;
    transition: position 2s ease-in-out;
    // padding: 2px 0;
    position: sticky;
    top:0;
    background-color: ${bgColor};
    p {
        color: ${navItemColor};
    }
    @media only screen and ${mediaFeatures['for-mobile-and-small-tablet']} { 
        border-bottom: 0;
    }
    box-shadow: ${headerShadow};
`;
const absoluteTransparentHeader = `
    z-index: 118;
`;
const fixedHeader = `
    z-index: 120;
`;
const webNavCont = (bgColor, isMobileOpen) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    padding: 2px 15px;
    // background-color: ${isMobileOpen ? bgColor : 'unset'};
`;
const logoCont = (logoHeight, imgRadius) => `
    display: flex;
    align-items: center;
    gap: 15px;
    img {
        height: ${logoHeight};
        // width: 45px;
        border-radius: ${imgRadius};
    }
    span {
        font-size: 21px;
        font-weight: 400;
        font-family: ${typography.shinyHeader5["font-family"]};
        color: ${colors.white};
    }
    @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
        span {
            font-size: 18.5px;
        }
    }
`;
const navItemCont = `
    display:flex;
    align-items:center;
    justify-content: space-between;
`;
const HideWebNav = `
    @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
		display: none;
	}
`;
const showMobileNav = `
    @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
		display: block;
	}
`;
const navItem = (borderBtmColor, activeColor) => `
	padding: 22px 9px;
	margin: 0 8px;
	display: flex;
	justify-content: center;
	align-items: center;
    cursor: pointer;
    border-bottom: 2px solid ${activeColor ? borderBtmColor : 'transparent'};
	p {
		font-size: 13px;
		font-weight: 600;
	}
`;
const mobileNavItemCont = `
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    padding:0 15px;
`;
const mobileNavItem = (borderBtmColor, activeColor) => `
    display: flex;
    align-items: center;
    height: 38px;
    padding: 8px;
    background: ${colors.blackShadow};
    border-radius: 6px;
    color: white;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    cursor: pointer;
    border: 1px solid ${activeColor ? borderBtmColor : 'transparent'};
    transition: box-shadow .3s;
    margin:7px 0; 
    justify-content:center;
    p {
        width: max-content;
    }
`;
const horizontalLine = `
	height: 1px;
	width:100%;
	background: ${colors["border-grey"]};
	margin: 8px 0;
`;
