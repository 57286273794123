export const calcDistance = (lat1, lon1, lat2, lon2) => {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    // assuming KM
    dist = dist * 1.609344;
    return dist;
};
export const getClosestCity = (result, cities, threshold) => {
    if (result && result.lat && result.long) {
        const closestCity = cities.find(city => {
            if (city) {
                // if loop only to satisfy broken types for
                // reduce in immutable 3.8
                const geolocationOfCurrentCityInList = city.get("geolocation");
                if (!geolocationOfCurrentCityInList) {
                    // no geolocation info available for this city
                    // so we can short circuit
                    return false;
                }
                const distanceFromCurrentCityInList = calcDistance(result.lat, result.long, geolocationOfCurrentCityInList.get("lat"), geolocationOfCurrentCityInList.get("lng"));
                return distanceFromCurrentCityInList < threshold;
            }
            return false;
        });
        return closestCity ? closestCity.get("name").toLowerCase() : undefined;
    }
};
