import { API_URL, PLATFORM, UNVERIFIED_API_KEY_ERROR_CODE } from "../../helpers/constants";
import { constructUrlParamString, generateLogoutLink, generateReprocessLink, generateSignupLink, generateLoginWithPaytmLink, generateRequestPasswordResetLink, generatePasswordResetLink, generatePaymentOptionsLink, generateCheckoutCartLink, generateUpdateCartLink, generateUpdateItemsInCartLink, generateApplyCouponLink, generateRemoveCouponLink, generateUpdateDeliveryDetailsLink, generateEnableCancellationProtectLink, generateDisableCancellationProtectLink, generateStripeRedirectLink, generateResetCartTimerLink } from "../../helpers/linkGenerators";
import urlEncode from "form-urlencoded";
import uuid from "uuid/v4";
import { PaytmDebitError } from "../../helpers/customErrors";
import { fromJS, OrderedMap, toJS } from "immutable";
import unescape from "lodash/string/unescape";
import { getDeviceInfo, getOSFromUserAgent } from "../misc/index";
import { fetchSiteDataWithAuth, postJSONSiteDataWithAuth, postToSiteWithAuth } from "../apiClientWithAuth";
import { postToSiteWithEncryptedSsoToken, postFormToSiteWithEncryptedSsoToken } from "./";
import { fetchPaytmAuthToken } from "../h5";
import { getApiKey, getApiSecret } from "../auth";
import { isFromApp } from "../../selectors";
/**
 * Makes a simple GET call to the API
 * @param {string} resource
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
// Polyfill for Promise.allSettled if it does not exist
Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(promises.map(p => p
    .then(value => ({
    status: "fulfilled",
    value
}))
    .catch(reason => ({
    status: "rejected",
    reason
})))));
export const fetchAPIData = function fetchAPIData(resource) {
    const API_OPTIONS = {
        headers: {
            "Accept-Encoding": "gzip"
        }
    };
    let res;
    return getDeviceInfo()
        .then(({ os }) => {
        if (PLATFORM && os) {
            API_OPTIONS.headers["X-Platform"] = PLATFORM;
            API_OPTIONS.headers["X-Platform-OS"] = os;
            API_OPTIONS.headers["X-Platform-Version"] = Number.MAX_SAFE_INTEGER; // Bypass version check
        }
        return fetch(`${API_URL}/${resource}`, API_OPTIONS);
    })
        .then(response => {
        res = response;
        if (response.status < 400) {
            return response;
        }
        else if (response.status === 404) {
            throw { name: "NotFoundError" };
        }
        const meta = {};
        // node-fetch response.headers is not iterable
        if (global._errs) {
            for (const [header, value] of response.headers) {
                meta[header] = value;
            }
            _errs.meta = meta;
        }
        throw new Error(`Bad status code ${response.status} ${API_URL}/${resource}`, response.json());
    })
        .then(response => response.json())
        .then(fromJS)
        .catch(e => {
        if (!global._errs) {
            // _errs will only be available on the client
            throw e;
        }
        if (!_errs.meta)
            _errs.meta = {};
        if (res) {
            res
                .text()
                .then(body => {
                _errs.meta.responseBody = body;
                _errs.push(e);
            })
                .catch(() => {
                // cached error reponses might come from s3 and might be html
                _errs.meta.parseError = "error: could not parse response body";
                _errs.push(e);
            });
        }
        else {
            _errs.meta.responseError = "error: undefined response";
            _errs.push(e);
        }
        throw e;
    });
};
export const handleSiteDataResponse = (response, resource) => {
    if (response.status < 400) {
        const isTicketDetailsPage = window.location.pathname.includes("/users/tickets/");
        if (response.redirected && isTicketDetailsPage) {
            window.location.href = "/users/login?redirectUrl=" + window.location.pathname;
        }
        return response;
    }
    const meta = {};
    // node-fetch response.headers is not iterable
    if (global._errs) {
        for (const [header, value] of response.headers) {
            meta[header] = value;
        }
        global._errs.meta = meta;
    }
    const CLIENT_CLOSED_REQUEST = 499;
    if (response.status !== CLIENT_CLOSED_REQUEST) {
        // Google bot creates a ton of 499s on /users/isLoggedIn, ignoring those
        response
            .text()
            .then(body => {
            global._errs.meta.responseBody = body;
        })
            .catch(() => {
            // cached error reponses might come from s3 and might be html
            global._errs.push(e);
        });
    }
    throw new Error(`Bad status code ${response.status} ${resource}`);
};
export const handleSiteDataErrors = e => {
    if (global._errs) {
        // _errs will only be available on the client
        global._errs.push(e);
    }
    throw e;
};
/**
 * Makes a simple GET call to express url
 * @param {string} resource
 * @return {Promise.<U>}
 */
export const fetchSiteData = function fetchSiteData(resource) {
    const requestId = uuid();
    const API_OPTIONS = {
        headers: {
            "Accept-Encoding": "gzip",
            "Request-Id": requestId
        },
        credentials: "same-origin"
    };
    return fetch(`${resource}`, API_OPTIONS)
        .then(response => handleSiteDataResponse(response, resource))
        .then(response => response.json())
        .then(fromJS)
        .catch(handleSiteDataErrors);
};
/**
 * Makes a POST request to express url
 * @param {string} resource
 * @param {resource} params
 * @return {Promise.<U>}
 */
export const postToSite = function postToSite(resource, body, getJson = false) {
    let parsedBody;
    try {
        parsedBody = body ? JSON.parse(body) : {};
    }
    catch (e) {
        console.log("error in parsing body", e);
        parsedBody = {};
    }
    if (parsedBody.isFromApp) {
        const apiKey = getApiKey();
        const secret = getApiSecret();
        parsedBody = Object.assign(Object.assign({}, parsedBody), { apiKey, secret });
    }
    let stringifiedBody = JSON.stringify(parsedBody);
    const requestId = uuid();
    const API_OPTIONS = {
        method: "POST",
        body: stringifiedBody,
        headers: {
            "Accept-Encoding": "gzip",
            "Content-Type": "application/json; charset=UTF-8",
            "Request-Id": requestId
        },
        credentials: "same-origin"
    };
    if (getJson) {
        return fetch(`${resource}`, API_OPTIONS).then(response => response.json());
    }
    return fetch(`${resource}`, API_OPTIONS);
};
export const postFormToSite = function (resource, formData) {
    if (formData.isFromApp) {
        const apiKey = getApiKey();
        const secret = getApiSecret();
        formData = Object.assign(Object.assign({}, formData), { apiKey, secret });
    }
    const requestId = uuid();
    const options = {
        method: "POST",
        body: urlEncode(formData),
        headers: {
            "Accept-Encoding": "gzip",
            "Content-Type": "application/x-www-form-urlencoded",
            "Request-Id": requestId
        },
        credentials: "same-origin"
    };
    let originalResponseObject = null;
    return fetch(resource, options)
        .then(response => {
        originalResponseObject = response;
        return originalResponseObject;
    })
        .then(response => response.text())
        .then(response => {
        try {
            return JSON.parse(response);
        }
        catch (e) {
            return response;
        }
    })
        .then(response => {
        if (originalResponseObject && originalResponseObject.status >= 400) {
            if (response.error_code === UNVERIFIED_API_KEY_ERROR_CODE) {
                logout(true);
                throw new Error(response.message);
            }
            if (response.message) {
                throw new Error(JSON.stringify({ status: originalResponseObject.status, message: response.message }));
            }
            else {
                throw new Error("Bad response code from the server");
            }
        }
        if (typeof response === "object") {
            return fromJS(response);
        }
        return response;
    });
};
/**
 * Makes a POST request to express url
 * @param {string} resource
 * @param {resource} params
 * @return {Promise.<U>}
 */
export const postToClevertap = function (resource, body) {
    const API_OPTIONS = {
        method: "POST",
        body,
        headers: {
            "Accept-Encoding": "gzip",
            "Content-Type": "application/json; charset=UTF-8"
        },
        credentials: "same-origin"
    };
    return fetch(`${resource}`, API_OPTIONS);
};
/**
 * Recently searched data. Fetched via proxy
 * from Sajari
 * @param {string} city
 */
export const fetchRecentSearchData = (city = "") => fetchAPIData(`sajari/recent?city=${city}`).then(response => response.get("recent"));
/**
 * Fetch popular data (masked as popular search data)
 * Ideally we should differentiate this from the popular
 * API on the homepage
 * @param {string} city
 */
export const fetchPopularSearchData = (city = "") => fetchAPIData(`sajari/popular?city=${city}`).then(response => response.get("popular"));
/**
 * Search actually
 * @param {string} searchQuery
 */
export const fetchUserSearchData = searchQuery => fetchAPIData(`sajari/search?terms=${searchQuery}`).then(response => response.set("query", searchQuery));
/**
 * Fetch article details
 * @param {string} slug
 */
export const fetchArticleData = ({ slug }) => fetchAPIData(`article/getBySlug/${slug}`);
/**
 * Fetch Artist
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchArtistData = ({ slug }) => fetchAPIData(`tag/list?tags[]=${encodeURIComponent(slug)}&model=event&operator=and&template=1`);
/**
 * Fetch City Artists
 /**
 * Fetch Artists
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchCityArtistsData = city => fetchAPIData(`tag/getArtistsForCity?city=${city}`);
/**
 * Fetch All Artists
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchAllArtistsData = () => fetchAPIData(`tag/type/artist`);
/**
 * Fetch Artist/Venue Articles
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchPartnerArticles = ({ slug }) => fetchAPIData(`tag/list?models=article&tags=${slug}`);
/**
 * Fetch Venue
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchVenueData = ({ slug }) => fetchAPIData(`tag/list?tags[]=${encodeURIComponent(slug)}&model=event&operator=and&template=1`);
/**
 * Fetch Venues
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchCityVenuesData = city => fetchAPIData(`tag/getVenuesForCity?city=${city}`);
/**
 * Fetch All Venues
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchAllVenuesData = city => fetchAPIData(`tag/type/venue`);
/**
 * Fetch Groups and Categories for routes
 */
export const fetchGroupsCategoriesData = () => fetchAPIData(`home/get-groups-and-category`);
/**
 * Fetch event details
 * @param {string} slug
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchEventData = ({ slug }) => fetchAPIData(`event/getBySlug/${slug}?tagSummaryView=true`);
export const fetchMonumentsData = () => fetchAPIData(`home/monuments?banner_id&city=mumbai&filterBy=go-out&norm=1`);
export const fetchStreamToken = (eventId, isLoggedIn) => {
    const apiEndpoint = isLoggedIn ? `/getstream/user/createUser` : `getstream/public/token?eventId=${eventId}`;
    const body = JSON.stringify({ eventId });
    if (isLoggedIn) {
        return postToSiteWithAuth(apiEndpoint, body)
            .then(response => response.json())
            .then(response => {
            return response.user;
        });
    }
    return fetchAPIData(apiEndpoint);
};
export const fetchFeedData = (params, isLoggedIn) => {
    let e = encodeURIComponent;
    let queryParams = Object.keys(params)
        .map(k => e(k) + "=" + e(params[k]))
        .join("&");
    const apiEndpoint = isLoggedIn ? `/getstream/user/feed?${queryParams}` : `getstream/public/feed?${queryParams}`;
    if (isLoggedIn) {
        return fetchSiteDataWithAuth(apiEndpoint).then(response => response.toJS());
    }
    return fetchAPIData(apiEndpoint).then(response => response.toJS());
};
/**
 * Fetch favourite count for event
 * @param {string} event Id
 */
export const fetchFavouriteStats = ({ eventId }) => fetchAPIData(`user_action/getfavouritecount?target_id=${eventId}`);
/**
 * Fetch the list of cities
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchCitiesData = () => fetchAPIData(`tag/home-cities`);
/**
 * Fetch Groups from Homepage data
 * @param city
 */
export const fetchGroupsData = city => fetchAPIData(`home?city=${city}&filterBy=go-out`);
/**
 * Check if the user is logged in
 */
export const fetchUserData = () => {
    if (window && window.location && window.location.search) {
        const parsedUrl = new window.URL(window.location.href);
        const token = parsedUrl.searchParams.get("token");
        const provider = parsedUrl.searchParams.get("provider");
        if (token && provider) {
            return fetchSiteData(`/users/isLoggedIn?token=${token}&provider=${provider}`);
        }
        else {
            return fetchSiteData(`/users/isLoggedIn`);
        }
    }
    return fetchSiteData(`/users/isLoggedIn`);
};
/**
 * Fetch tag display details
 */
export const fetchTagData = ({ tagSlugs, models, dataId }) => {
    const tagSlugString = tagSlugs.map(t => `tags=${t}`).join("&");
    const modelSlugString = models.map(m => `models=${m}`).join("&");
    const queryString = `${tagSlugString}&${modelSlugString}`;
    return fetchAPIData(`tag/list?${queryString}`);
};
/**
 * Fetch cart details
 */
export const fetchCartData = () => {
    let cart;
    const cartElement = document.getElementById("cart");
    if (cartElement) {
        cart = cartElement && cartElement.getAttribute("content");
        if (cart) {
            const unescaped = unescape(cart);
            const result = JSON.parse(unescaped);
            return Promise.resolve(fromJS(result));
        }
        return fetchSiteData(`/cart`);
    }
    return fetchSiteData(`/cart`);
};
/**
 * Clear cart
 */
export const clearCartData = () => postToSite(`/cart/clear`);
/**
 * For a given event, get recommended / similar events
 * @param {object} queryOpts
 * @param {string} queryOpts.model
 * @param {string} queryOpts.slug
 * @param {string} [queryOpts.category]
 * @param {string} [queryOpts.city]
 */
export const fetchRelatedData = queryOpts => fetchAPIData(`sajari/related?${constructUrlParamString(queryOpts)}`).then(results => results.get("related"));
/**
 * Fetches buy together data for the user and the given event params
 * @param {Object} params
 */
export const fetchUserBuyTogetherDataFromApi = params => {
    let e = encodeURIComponent;
    let queryParams = Object.keys(params)
        .map(k => e(k) + "=" + e(params[k]))
        .join("&");
    return fetchSiteData(`/groups/groupDataForEventAndUser?${queryParams}`)
        .then(response => response.get("data"))
        .then(function (data) {
        return data.noOffer ? null : data;
    });
};
/**
 * Fetches event favourites data for the user
 * @param {Object} params
 */
export const fetchFavouritesData = params => {
    const favouritesEndpoint = "/user_action/favourite", path = params ? `${favouritesEndpoint}?${params}` : favouritesEndpoint;
    return fetchSiteDataWithAuth(path)
        .then(response => response.get("data"))
        .then(function (data) {
        return data.get("events") || [];
    });
};
/**
 * Fetch Club
 * @return {Promise<Immutable.Map|Immutable.List>}
 */
export const fetchClubData = ({ slug }) => fetchAPIData(`article/getBySlug/${slug}`);
export const fetchItemCard = itemId => fetchAPIData(`item/getItemDetailsForClient/${itemId}`);
export const logout = (persistCart = false) => fetchSiteData(generateLogoutLink(persistCart));
export const reprocessCart = isFromApp => postToSiteWithEncryptedSsoToken(generateReprocessLink(), isFromApp ? { isFromApp } : "")
    .then(response => response.json())
    .then(json => fromJS(json))
    .then(data => data.get("cart"));
export const signup = (email, password, confirmPassword, firstName, lastName) => {
    return postFormToSite(generateSignupLink(), {
        userObj: {
            email,
            password,
            confirmpassword: confirmPassword,
            first_name: firstName,
            last_name: lastName
        }
    });
};
export const loginWithPaytmApi = (customerId, firstName, lastName, hashedEncSsoToken) => {
    return postFormToSite(generateLoginWithPaytmLink(), {
        customerId,
        firstName,
        lastName,
        hashedEncSsoToken
    });
};
export const requestPasswordReset = email => {
    return postFormToSite(generateRequestPasswordResetLink(), { email });
};
export const resetPassword = (resetHash, email, password, confirmPassword) => {
    return postFormToSite(generatePasswordResetLink(), {
        resetHash,
        email,
        password,
        confirmPassword
    });
};
export const getPaymentOptions = (transactionId, apiKey) => {
    return fetchSiteData(generatePaymentOptionsLink(transactionId, apiKey)).then(options => {
        // Ordering map according to the sort order passed by payments
        const extras = options.get("extras");
        const sortOrder = options.get("sortOrder");
        if (!sortOrder)
            return options;
        let orderedOptions = new OrderedMap();
        sortOrder.map(optionKey => {
            let optionValue = options.get(optionKey);
            orderedOptions = orderedOptions.set(optionKey, optionValue);
        });
        return {
            options: orderedOptions,
            offer_requested: options.get("offer_requested"),
            extras
        };
    });
};
export const getElementsToken = (transactionId, apiKey, isRupayEvent = false) => fetchSiteData(`/payments/initiateElementsTransaction?apiKey=${apiKey}&transactionId=${transactionId}&isRupayEvent=${isRupayEvent}`);
// paytm wallet linking
export const registerPaytmNumber = payload => postFormToSite("/payments/paytm/register", payload);
export const submitPaytmOTP = payload => postFormToSite("/payments/paytm/otp", payload);
export const getPaytmBalance = apiKey => fetchSiteData(`/payments/paytm/getBalance?apiKey=${apiKey}`);
export const debitPaytm = payload => {
    return postFormToSite("/payments/paytm/debit", payload).catch(err => {
        throw new PaytmDebitError(err.message);
    });
};
export const processIntent = payload => {
    return postFormToSite("/payments/upi/processIntent", payload);
};
export const checkTransactionStatus = payload => fetchSiteData(`/payments/paytm/checkTransactionStatus?transactionId=${payload.transactionId}`);
export const fetchBinDetails = payload => postFormToSite("/payments/fetchBinDetails", payload);
export const validateVPA = payload => postFormToSite("/payments/validateVPA", payload);
export const checkoutCart = (billingName, billingEmail, billingPhoneNumber, whatsappNotificationPreference = false, billingZip, billingState = "Maharashtra", isFromApp = false, isORFlow = false, isDeferredCheckout = true, isCancellationProtectEnabled = false) => {
    const payload = {
        billing_email: billingEmail,
        billing_tel: billingPhoneNumber,
        billing_name: billingName,
        whatsapp_notification_preference: whatsappNotificationPreference,
        billing_zip: billingZip,
        billing_state: billingState,
        isFromApp: isFromApp,
        isORFlow: isORFlow,
        isDeferredCheckout: isDeferredCheckout
    };
    if (isCancellationProtectEnabled) {
        payload.cancellation_protected = true;
    }
    return postFormToSiteWithEncryptedSsoToken(generateCheckoutCartLink(), payload);
};
export const checkoutRSVPCart = (payload, isFromApp = false) => {
    return postFormToSiteWithEncryptedSsoToken("/transaction/commitRsvpCart", Object.assign(Object.assign({}, payload), { isFromApp }));
};
export const getStripeRedirectUrl = payload => {
    return postFormToSite(generateStripeRedirectLink(), payload);
};
export const updateCart = (payload, isFromApp, embed, ref_source) => postFormToSiteWithEncryptedSsoToken(generateUpdateCartLink(), {
    item: JSON.stringify(payload),
    isFromApp,
    embed,
    ref_source
});
export const enableCancellationProtect = () => postToSiteWithEncryptedSsoToken(generateEnableCancellationProtectLink(), {});
export const disableCancellationProtect = () => postToSiteWithEncryptedSsoToken(generateDisableCancellationProtectLink(), {});
export const resetCartTimer = () => {
    return postFormToSiteWithEncryptedSsoToken(generateResetCartTimerLink(), {});
};
export const updateItemsInCart = (items, isFromApp, embed, ref_source) => {
    return postToSiteWithEncryptedSsoToken(generateUpdateItemsInCartLink(), {
        items,
        isFromApp,
        embed,
        ref_source
    })
        .then(response => response.json())
        .then(json => fromJS(json));
};
export const applyDiscountCoupon = (coupon, body) => {
    return postToSiteWithEncryptedSsoToken(generateApplyCouponLink(coupon), body)
        .then(response => response.json())
        .then(json => fromJS(json));
};
export const removeDiscountCoupon = isFromApp => {
    return postToSiteWithEncryptedSsoToken(generateRemoveCouponLink(), { isFromApp })
        .then(response => response.json())
        .then(json => fromJS(json));
};
export const updateDeliveryDetails = (deliveryDetails, isFromApp) => {
    return postFormToSiteWithEncryptedSsoToken(generateUpdateDeliveryDetailsLink(), {
        isFromApp,
        delivery_details: {
            name: deliveryDetails.name,
            address_1: deliveryDetails.addressLine1,
            address_2: deliveryDetails.addressLine2,
            city: deliveryDetails.city,
            state: deliveryDetails.state,
            landmark: deliveryDetails.landmark,
            pincode: deliveryDetails.pinCode,
            address_type: deliveryDetails.addressType,
            shipping_tel: deliveryDetails.indianPhoneNumber
        }
    });
};
export const clearDeliveryDetails = isFromApp => {
    return postFormToSiteWithEncryptedSsoToken(generateUpdateDeliveryDetailsLink(), { isFromApp });
};
export const fetchPaymentSuccessData = transactionId => fetchSiteData(`/payments/discount/generateCoupon/${transactionId}`);
export const fetchPaymentStatusData = orderId => {
    return fetchPaytmAuthToken().then(sso_token => {
        return postToSite("/transaction/getOrderStatus", JSON.stringify({
            orderId: orderId,
            sso_token: sso_token
        }), true);
    });
};
export const fetchTicketsData = (page, limit) => fetchSiteData(`/users/tickets?page=${page}&limit=${limit}`);
export const fetchTicketsShortcode = shortcode => fetchSiteData(`/users/tickets/fromShortCode/${shortcode}`);
export const fetchStashesData = _id => fetchSiteDataWithAuth(`/user/stash?user_id=${_id}`);
export const fetchStashBookData = () => postJSONSiteDataWithAuth("/tickets/stashBook");
export const fetchTicketsFromTransaction = ({ transactionId }) => fetchSiteData(`/users/tickets/fromTransaction/${transactionId}`);
export const postRequestCancellation = soldInventoryIds => postToSiteWithEncryptedSsoToken("/users/requestCancellation", {
    sold_inventory_ids: soldInventoryIds
});
export const fetchLogisticsData = transactionId => {
    return fetchSiteData(`/users/getawb/${transactionId}`);
};
/**
 * Fetch Active Offers for EDP
 * @param queryParams
 */
export const fetchActiveOffers = queryParams => {
    return fetchAPIData(`discount/coupon/active-offers?${queryParams}`);
};
export const toggleFavouriteForEvent = (action, body) => {
    const favouritesEndpoint = "/user_action", path = action === "unfavourite" ? `${favouritesEndpoint}/undo` : favouritesEndpoint;
    return postToSiteWithAuth(path, body);
};
export const sendOTP = email => {
    const otpParams = {
        key: "email",
        value: email
    };
    const body = JSON.stringify(otpParams);
    return postToSite(`/users/sendOTP`, body)
        .then(response => response.json())
        .then(response => {
        return response;
    });
};
export const fetchGoogleoAuthUrl = state => {
    const params = {
        state
    };
    const body = JSON.stringify(params);
    return postToSite(`/users/fetchGoogleoAuthUrl`, body).then(response => response.json());
};
export const fetchTokenInfo = idToken => {
    const oauthEndpointURL = "https://oauth2.googleapis.com/tokeninfo?id_token=";
    return fetch(`${oauthEndpointURL}${idToken}`).then(response => response.json());
};
export const resendTicketEmail = shortcode => {
    const path = `/transaction/resend?shortcode=${shortcode}`;
    return fetchSiteDataWithAuth(path).then(response => {
        if (response.get("result") === "ok") {
            return { emailResent: true };
        }
        return { emailResent: false };
    });
};
export const checkTicketPurchase = eventId => {
    const query = { filter: { key: "event_id", value: eventId } };
    const body = JSON.stringify(query);
    return postToSite(`/users/checkPurchaseStatus`, body)
        .then(response => response.json())
        .then(response => {
        return response.purchased;
    });
};
export const fetchRelatedEventsData = (event_id, city) => {
    return fetchAPIData(`home/getRelatedEvents?city=${city}&event_id=${event_id}`);
};
export const fetchPaytmUser = auth_code => {
    return postFormToSite(`/users/fetchPaytmUser`, {
        auth_code
    });
};
export const fetchEventAdBannerData = (event_id, city) => {
    const devicePlatform = getOSFromUserAgent();
    return fetchAPIData(`event/getEventAndCityAdBanner?city=${city}&event_id=${event_id}&devicePlatform=${devicePlatform}`);
};
export const fetchStateFromPincode = pincode => postToSite(`/validate/pincode`, JSON.stringify({ pincode: pincode })).then(response => response.json());
export const fetchStateFromPincodeCheck = (pincode, eventIdsArray) => {
    const eventIds = eventIdsArray.length > 0 ? eventIdsArray.join(",") : "";
    return postToSite(`/validate/pincode/check`, JSON.stringify({ pincode: pincode, eventIds: eventIds })).then(response => response.json());
};
export const fetchMatchScheduleEvents = async () => {
    const eventTag = "indian-super-league-2024-25";
    const res = await fetchAPIData(`tag/list?tags=${eventTag}`);
    const data = res.toJS();
    return data.data.list;
};
export const fetchIPLMatchScheduleEvents = async () => {
    const eventTag = "ipl-2024-microsite";
    const res = await fetchAPIData(`tag/list?tags=${eventTag}`);
    const data = res.toJS();
    return data.data.list;
};
export const fetchConfigByName = async (configName) => fetchAPIData(`configurations?name=${configName}`);
export const fetchDynamicEvents = async (tagId, startTime) => {
    const eventTag = tagId;
    const res = await fetchAPIData(`tag/list?tags=${eventTag}&startUtc=${startTime}`);
    const data = res.toJS();
    return data.data.list;
};
export const fetchDynamicScheduleData = async (tagIds) => {
    const settledPromisesData = await Promise.allSettled(tagIds.map(tagId => fetchAPIData(`tag/list?tags=${tagId}`)));
    const scheduleData = settledPromisesData.map(item => {
        if (item.status === 'fulfilled') {
            return item.value.toJS().data.list;
        }
        return [];
    });
    return scheduleData;
};
export const fetchDynamicPageData = async (slug) => {
    let data = {};
    try {
        if (!slug || slug === 'undefined.js')
            return data;
        data = await import(`../../components/DynamicEventsLanding/micrositeConfigs/${slug}.ts`);
        return data.default;
    }
    catch (err) {
        console.log(`${slug}.ts import error: `, err);
    }
    return data;
};
export const fetchNewYearEvents = async (tagId) => {
    const eventTag = tagId;
    const res = await fetchAPIData(`tag/list?tags=${eventTag}&operator=and`);
    const data = res.toJS();
    return data.data.list;
};
export const fetchNewYearOffersData = async (queryParams) => {
    const res = await fetchAPIData(`discount/coupon/active-offers?${queryParams}`);
    const data = res.toJS();
    return data.data;
};
// export const paytmCheckout = async (transactionId, payment_intent) => {
// 	// const sso_token = await fetchPaytmAuthToken();
// 	console.log("paytmCheckout")
// 	return postFormToSite("/cart/checkoutPaytmCart", { transactionId, payment_intent, sso_token:'' });
// }
export const fetchNewBuyFlowEventIds = () => {
    return fetchSiteData(`/users/newBuyFlowEvents`);
};
export const fetchUserCountry = (slug, event_id) => {
    return fetchSiteData(`/users/fetchUserCountry?slug=${slug}&event_id=${event_id}`);
};
export const getSamsungDeeplinkViaTransaction = async (transactionId) => {
    const path = `/users/getSamsungWalletDeeplink?type=transactionId&id=${transactionId}`;
    const res = await fetchSiteData(path);
    const data = res.toJS();
    return data.data;
};
export const getSamsungDeeplinkViaShortCode = async (shortcde) => {
    const path = `/users/getSamsungWalletDeeplink?type=shortcode&id=${shortcde}`;
    const res = await fetchSiteData(path);
    const data = res.toJS();
    return data.data;
};
export const isSamsungWalletSupported = async (deviceName) => {
    const samsungDeviceCheckEndpoint = "https://api-us3.mpay.samsung.com/wallet/cmn/v2.0/device/available";
    try {
        const res = await fetch(`${samsungDeviceCheckEndpoint}?modelName=${deviceName}&serviceType=WALLET`);
        const data = await res.json();
        return {
            status: 200,
            data: data.available
        };
    }
    catch (err) {
        console.log("error in samsung API", error);
        return {
            status: 400,
            data: false
        };
    }
};
