/** @jsx jsx */
import { css, jsx } from "@emotion/core";
const LoaderContainer = `
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
	min-height: 250px;
    overflow: hidden;
    align-items: center;
	justify-content: center;
	margin: 40px 0;
`;
const LoaderImg = `
    width: 50px;
    height: 50px;
`;
const Loader = ({ style = "" }) => (<div css={css([LoaderContainer, style])}>
		<img src="/assets/img/insider-heart-loader.gif" alt="loading..." css={css(LoaderImg)}/>
	</div>);
export default Loader;
