import React from "react";
import { errorLoading } from "../helpers/misc";
const asyncGroupHoc = (group, timeFilter) => (_, cb) => import(/* webpackChunkName: "GroupPage" */ "../containers/GroupViewWithSubscription")
    .then(categoryBundle => categoryBundle.default)
    .then(GroupContainer => {
    const GroupViewWithSubscriptionWrapper = props => {
        const priceFilter = props.location.query.priceFilter;
        const typeFilter = props.location.query.type;
        return (<GroupContainer {...props} category={"category" in props.params ? props.params.category : null} group={group} timeFilter={timeFilter} priceFilter={priceFilter} typeFilter={typeFilter}/>);
    };
    GroupViewWithSubscriptionWrapper.preloadChunkName = "GroupPage";
    GroupViewWithSubscriptionWrapper.cacheString = "public, max-age=300";
    // hoist fetch data one level higher
    GroupViewWithSubscriptionWrapper.fetchData = GroupContainer.fetchData;
    return cb(null, GroupViewWithSubscriptionWrapper);
})
    .catch(errorLoading);
export default asyncGroupHoc;
