import React from "react";
export class CrossIcon extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    render() {
        return (<svg height={this.props.size} width={this.props.size} viewBox="0 0 40 40" style={{ display: `block`, cursor: `pointer` }} xmlnsXlink="http://www.w3.org/1999/xlink" onClick={this.props.onClick} id={this.props.id}>
				<defs>
					<path id="a" d="M12 10.232l7.116-7.116a1.25 1.25 0 0 1 1.768 1.768L13.768 12l7.116 7.116a1.25 1.25 0 0 1-1.768 1.768L12 13.768l-7.116 7.116a1.25 1.25 0 0 1-1.768-1.768L10.232 12 3.116 4.884a1.25 1.25 0 0 1 1.768-1.768z"/>
					<mask id="b" fill="#fff">
						<use fill="#fff" fillRule="evenodd" xlinkHref="#a"/>
					</mask>
				</defs>
				<g fill="none" fillRule="evenodd">
					<circle cx={20} cy={20} fill={this.props.backgroundColor} r={20}/>
					<g fill={this.props.strokeColor} transform="translate(8 8)">
						<use fillRule="nonzero" xlinkHref="#a"/>
						<g fillRule="evenodd" mask="url(#b)">
							<path d="M0 0h24v24H0z"/>
						</g>
					</g>
				</g>
			</svg>);
    }
}
CrossIcon.defaultProps = {
    size: 24,
    strokeColor: "#373435",
    backgroundColor: "#fff"
};
